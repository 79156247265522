import { createRoot } from "react-dom/client"
import ModalFrame, { ModalProps } from "./modalFrame"
import Modal from "./modalRootNode"

const show = (props: ModalProps) => {
  const divWrap = document.createElement("div")
  const root = createRoot(divWrap)

  document.body.appendChild(divWrap)

  function removeNode(callback: Function | undefined) {
    callback && callback()
    root.unmount()
    divWrap && document.body.removeChild(divWrap)
  }

  const { onClose } = props

  const handleClose = () => {
    removeNode(onClose)
  }

  const _props = {
    open: true,
    onClose: handleClose,
    title: props.title,
  }

  root.render(
    <ModalFrame {..._props}>
      <pre className="add-tip l-lh6 l-center">{props.message}</pre>
    </ModalFrame>,
  )

  return null
}

export const showModal = (props: ModalProps) => {
  show(props)
}

export default Modal
