import { Link, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState, Fragment } from "react"
import classNames from "classnames"
import i18n, { DEFAULT_LANGUAGE } from "../i18n/index"
import { Listbox } from "@headlessui/react"

const languages = [
  { label: "English", value: "en" },
  { label: "Deutsch", value: "de" },
  { label: "العربية", value: "ar" },
  { label: "Español", value: "es" },
  { label: "Français", value: "fr" },
  { label: "日本語", value: "ja" },
  { label: "한국어", value: "ko" },
  { label: "Português", value: "pt" },
  { label: "Русский", value: "ru" },
  { label: "Tiếng Việt", value: "vi" },
  { label: "繁體中文", value: "zh-TW" },
]

const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

function Header() {
  const { lng } = useParams()
  const navigate = useNavigate()

  const [rokSiteUrl, setRokSiteUrl] = useState("https://rok.lilith.com/en")
  const [selectLng, setSelectLng] = useState(
    languages.find((lng) => lng.value === DEFAULT_LANGUAGE),
  )

  useEffect(() => {
    switch (lng) {
      case "en":
        setRokSiteUrl("https://rok.lilith.com/en")
        break
      case "ja":
        setRokSiteUrl("https://rok-jp.lilith.com/")
        break
      case "ko":
        setRokSiteUrl("https://rok-kr.lilith.com/")
        break
      case "zh-TW":
        setRokSiteUrl("https://www.ori.tw/rok")
        break
      case "zh-CN":
        setRokSiteUrl("https://rok-cn.lilith.com/")
        break
      default:
        setRokSiteUrl("https://rok.lilith.com/en")
    }
    setSelectLng(languages.find((lang) => lang.value === lng))
    i18n.changeLanguage(lng)
  }, [lng])

  const handleChangeLanguage = (lang: any) => {
    i18n.changeLanguage(lang.value)
    navigate(window.location.pathname.replace(`/${lng}`, `/${lang.value}`))
    setSelectLng(lang)
  }

  return (
    <header className="l-flex l-white">
      <div className="left">
        <div className="lh-logo">
          <a href={rokSiteUrl} target="_blank"></a>
          <Link to={`/${lng}`} />
          <img src={`/img/${lng}/logo.png`} />
        </div>
      </div>
      <div className="right">
        {!isCnSite && (
          <div className="header-lang l-flex">
            <div className="icon">
              <img src="/img/lang.png" />
            </div>
            <Listbox
              value={selectLng}
              onChange={handleChangeLanguage}
              as="dl"
              className="lh-lang"
            >
              <Listbox.Button as="dt">
                <span>{selectLng?.label}</span>
              </Listbox.Button>
              <Listbox.Options as="dd">
                {languages.map((lang, index) => (
                  <Listbox.Option
                    key={`lang-${index}`}
                    value={lang}
                    as={Fragment}
                  >
                    {({ selected }) => (
                      <a className={classNames({ on: selected })}>
                        {lang.label}
                      </a>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
            {/* <dl className="lh-lang">
              <dt>
                <span>{languages[lng ?? DEFAULT_LANGUAGE]}</span>
              </dt>
              <dd>
                {lngs.map((lang: string) => (
                  <a key={lang} onClick={() => handleChangeLanguage(lang)}>
                    {languages[lang]}
                  </a>
                ))}
              </dd>
            </dl> */}
          </div>
        )}
      </div>
      <a href="#" className="nav-btn">
        <i></i>
      </a>
    </header>
  )
}

export default Header
