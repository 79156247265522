import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { RouterProvider, createBrowserRouter, redirect } from "react-router-dom"
import { store } from "@/stores"
import Layout from "./layout"
import { Home } from "@/pages/Home"
import "./i18n"
import "@/styles/index.css"
import { Rules } from "@/pages/Rules"
import { TeamDetail } from "@/pages/Team/maskId"
import { AllTeam } from "@/pages/Team/All"
import AuthGuardRoute from "./routers/AuthGuardRoute"
import { CreateTeam } from "./pages/Create"
import i18n, { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGE } from "./i18n/index"
import { ScrollRestoration } from "react-router-dom"
import { GoogleAnalyticsProvider } from "react-ga4-gtag"

/**
 * 处理国际化路由中间件，如果发现国际化代码的path不合法，则跳转到默认的语言
 *
 * @param {string} lng 路径上的语言
 * @param {Function | undefined} next router的回调，如果不执行任何操作请省略
 * @returns
 */

const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

const GID = isCnSite ? "G-CC0PHF6CMT" : "G-1SVXVX30ZF"

const isLngSupported = (lng: string) => {
  if (!lng) return false
  if (lng.startsWith("zh-TW")) return true
  if (isCnSite && lng.startsWith("zh-CN")) return true
  const _lng = lng.substring(0, 2)
  return SUPPORTED_LANGUAGE.includes(_lng)
}

const languageMiddleware = (args: any) => {
  const { params } = args
  if (!params.lng || !isLngSupported(params.lng)) {
    if (isLngSupported(i18n.language)) return redirect(`/${i18n.language}`)
    else return redirect(`/${DEFAULT_LANGUAGE}`)
  }

  return {}
}

const browserRouter = (path: string, componet: React.JSX.Element) => ({
  path,
  element: (
    <>
      <Layout>{componet}</Layout>
      <ScrollRestoration />
    </>
  ),
  loader: (args: any) => {
    return languageMiddleware(args)
  },
})

const router = createBrowserRouter([
  browserRouter("/:lng?", <Home />),
  browserRouter("/:lng?/rules", <Rules />),
  browserRouter("/:lng?/team/:maskId", <TeamDetail />),
  // browserRouter(
  //   "/:lng?/team/update",
  //   <AuthGuardRoute>
  //     <CreateTeamForm shouldScroll={false} />
  //   </AuthGuardRoute>,
  // ),
  browserRouter("/:lng?/teams", <AllTeam />),
  browserRouter(
    "/:lng?/enroll",
    <AuthGuardRoute>
      <CreateTeam />
    </AuthGuardRoute>,
  ),
  // browserRouter("/:lng?/join", <Join />),
  browserRouter("*", <Home />),
])

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <GoogleAnalyticsProvider measurementId={GID}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </GoogleAnalyticsProvider>
  </React.StrictMode>,
)
