export function FooterEn() {
  return (
    <footer className="l-pd50">
      <div className="l-container l-white l-lh8 l-center">
        <div className="logo">
          <img src="/img/en-logo.png" />
        </div>
        <div className="agreement">
          <a
            href="https://www.lilithgames.com/privacy?locale=en-US"
            target="_blank"
          >
            PRIVACY POLICY
          </a>
          <a
            href="https://www.lilithgames.com/termofservice?locale=en-US"
            target="_blank"
          >
            TERMS OF SERVICE
          </a>
        </div>
        <p>© 2023, LILITH GAMES - ALL RIGHTS RESERVED</p>
      </div>
    </footer>
  )
}
