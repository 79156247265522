import { t } from "i18next"
import { useState, useEffect } from "react"
import { useParams } from "react-router"

export function Rules() {
  const [content, setContent] = useState(t("rulesContent"))

  const { lng } = useParams()

  useEffect(() => {
    setContent(t("rulesContent"))
  }, [lng])
  return (
    <main>
      <section className="lh-banner ban l-relative">
        <img src="/img/ban.png" />
      </section>
      <section className="lh-other">
        <div className="l-container">
          <div className="other-cont">
            <div className="border"></div>
            <div className="index-title l-relative">
              <img src="/img/bicon3.png" />
              <div className="cont">
                <div>
                  <h3 className="name l-font70 honghuang">{t("rules")}</h3>
                </div>
              </div>
            </div>
            <div className="manage-tip l-center l-font30 l-orange l-pd50 l-bold">
              {t("rulesTitle")}
            </div>
            <div className="rule-ones l-font16">
              <pre dangerouslySetInnerHTML={{ __html: content }}></pre>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
