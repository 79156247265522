function Footer() {
  return (
    <footer className="l-pd50">
      <div className="l-container l-white l-lh8 l-center">
        <div className="logo">
          <img src="/img/logo1.png" />
        </div>
        <p>
          上海莉莉丝网络科技有限公司
          上海市闵行区莲花路1978号华纳时尚酒店园区新意城B栋3楼
        </p>
        <p>
          抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。
        </p>
        <p>
          沪网文[2019] 0390-033号 沪ICP备15032795号-1 新广出审[2017]2880号 ISBN
          978-7-7979-6310-7
        </p>
        <p>
          增值电信业务经营许可证:沪B2-20160037 ©2016-2020 莉莉丝游戏版权所有
        </p>
        <div className="agreement">
          <a href="https://www.lilithgames.com/cn/termofservice.html">
            服务条款
          </a>
          <a
            href="https://www.lilithgames.com/cn/privacy_lilith.html"
            target="_blank"
          >
            隐私政策
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
