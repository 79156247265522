import { t } from "i18next"
import { InvitionModal } from "@/components/InvitionModal"
import { QuitHistoryModal } from "@/components/QuitHistoryModal"
import { useAppSelector, useAppDispatch } from "@/hooks"
import { selectUser } from "@/stores/auth.slice"
import { useEffect, useState, MouseEvent } from "react"
import { useParams, useNavigate } from "react-router"
import { Team, User } from "@/types"
import {
  getTeam,
  removeTeamMember,
  disbandTeam,
  quitTeam,
} from "@/services/team.service"
import { ConfirmModal } from "@/components/ConfirmModal"
import { showModal } from "@/components/Modal"
import { updateUserTeamInfo } from "@/stores/auth.slice"
import { toggleLoginModal } from "@/stores/modal.slice"
import { JoinFormModal } from "@/components/JoinFormModal"
const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

const getIMName = (index: number | null | undefined) => {
  if (index != null && index >= 0 && index < 6) {
    const IMs = ["Wechat", "Discord", "WhatsApp", "Kakao", "Line", "Zalo"]

    return IMs[index]
  }
  return null
}

export function TeamDetail() {
  const { maskId } = useParams()
  const [isMyTeam, setIsMyTeam] = useState(false)
  const currentUser = useAppSelector(selectUser)
  const [team, setTeam] = useState<Team | null | undefined>(null)
  const [isLeader, setIsLeader] = useState(false)
  const [isMember, setIsMember] = useState(false)
  const [isTeamFull, setIsTeamFull] = useState(false)

  const [isDisband, setIsDisband] = useState(false)

  const refreshTeamInfo = () => {
    if (maskId) {
      getTeam(maskId, currentUser?.id).then((teamRes) => {
        if (teamRes && teamRes.id) {
          setTeam(teamRes)

          if (currentUser && currentUser.id === teamRes?.leader.id)
            setIsLeader(true)
          else setIsLeader(false)

          if (
            currentUser &&
            teamRes &&
            teamRes.members &&
            teamRes.members.length > 0
          ) {
            const memberIndex = teamRes.members.findIndex(
              (member) => member.id === currentUser.id,
            )
            setIsMember(memberIndex > -1)
            setIsTeamFull(teamRes.members.length >= 35)
          } else setIsMember(false)
        } else {
          navigete(`/${lng}`)
        }
      })
    }
  }

  useEffect(() => {
    if (!isDisband) {
      refreshTeamInfo()
    }

    if (currentUser && currentUser.teamMaskId === maskId) setIsMyTeam(true)
    else setIsMyTeam(false)
  }, [currentUser])

  const [openQuitRecords, setOpenQuitRecords] = useState(false)
  const [openInvition, setOpenInvition] = useState(false)

  const { lng } = useParams()

  const [entryTimes, setEntryTimes] = useState<string[]>([])

  useEffect(() => {
    setEntryTimes([
      `${t("Saturday")} 12:00(UTC+0)	=${t("Saturday")} 20:00(UTC+8)	=${t(
        "Saturday",
      )} 05:00(UTC-7)`,
      `${t("Saturday")} 14:00(UTC+0)	=${t("Saturday")} 22:00(UTC+8)	=${t(
        "Saturday",
      )} 07:00(UTC-7)`,
      `${t("Sunday")} 02:00(UTC+0)	=${t("Sunday")} 10:00(UTC+8)	=${t(
        "Saturday",
      )} 19:00(UTC-7)`,
      `${t("Sunday")} 04:00(UTC+0)	=${t("Sunday")} 12:00(UTC+8)	=${t(
        "Saturday",
      )} 21:00(UTC-7)`,
      `${t("Sunday")} 06:00(UTC+0)	=${t("Sunday")} 14:00(UTC+8)	=${t(
        "Saturday",
      )} 23:00(UTC-7)`,
    ])
  }, [lng])

  const entryTimesCompanents = (arr: number[]) => {
    return (
      <>
        {arr.map((index) => {
          if (entryTimes[index])
            return <p key={`rok:et-${index}`}>{entryTimes[index]}</p>
        })}
      </>
    )
  }

  const openRemoveMemberConfirm = (e: MouseEvent, member: User) => {
    e.preventDefault()
    setOpenRemoveConfirm(true)
    setCurrentMember(member)
  }

  const [currentMember, setCurrentMember] = useState<User | null | undefined>(
    null,
  )

  const [openRemoveConfirm, setOpenRemoveConfirm] = useState(false)

  const handleRemoveMemberConfirm = () => {
    if (currentMember) {
      removeTeamMember(currentMember.id).then((res) => {
        if (res && res.code === 0) {
          setOpenRemoveConfirm(false)
          if (maskId) {
            getTeam(maskId, currentUser?.id).then((team) => {
              if (team && team.id) setTeam(team)
            })
          }
        }
      })
    }
  }

  const [openDisbandConfirm, setOpenDisbandConfirm] = useState(false)

  const dispatch = useAppDispatch()
  const navigete = useNavigate()

  const handleDisbandConfirm = () => {
    disbandTeam().then((res) => {
      if (res && res.code === 0) {
        setOpenDisbandConfirm(false)
        showModal({
          title: t("success"),
          message: t("disbandSuccess"),
          onClose: () => {
            navigete(`/${lng}`, { replace: true })
            setIsDisband(true)
            dispatch(updateUserTeamInfo({ teamId: null, teamMaskId: null }))
          },
        })
      }
    })
  }

  const [openQuitConfirm, setOpenQuitConfirm] = useState(false)
  const handleQuitTeam = () => {
    quitTeam().then((res) => {
      if (res && res.code === 0) {
        setOpenQuitConfirm(false)
        dispatch(updateUserTeamInfo({ teamId: null, teamMaskId: null }))
      }
    })
  }

  const [openJoinConfirm, setOpenJoinConfirm] = useState(false)

  const onJoinTeamClick = () => {
    if (!currentUser) {
      dispatch(toggleLoginModal())
    } else if (currentUser.teamId != null) {
      showModal({
        title: t("joinFailed"),
        message: t("onTeam"),
      })
    } else setOpenJoinConfirm(true)
  }

  const handleJoined = () => {
    setOpenJoinConfirm(false)
    refreshTeamInfo()
  }

  return (
    <main>
      <section className="lh-banner ban l-relative">
        <img src="/img/ban.png" />
      </section>
      <section className="lh-other">
        <div className="l-container">
          <div className="other-cont">
            <div className="border"></div>
            <div className="index-title l-relative">
              <img src="/img/bicon3.png" />
              <div className="cont">
                <div>
                  <h3 className="name l-font70 honghuang">
                    {isMyTeam ? t("myTeam") : t("teamDetails")}
                  </h3>
                </div>
              </div>
            </div>
            <div className="join-info l-relative">
              <div className="left">
                <img src="/img/b2.png" />
                <div className="cont l-center">
                  <div className="icon">
                    {team && team.guidon && (
                      <img src={`/img/guidon/${team.guidon}.png`} />
                    )}
                  </div>
                  <div className="join-info-item l-font16">
                    <h3>{t("totalPower")}</h3>
                    <p className="l-font40 l-orange impactMTStd l-mg15 l-lh0">
                      {team?.totalPower}
                    </p>
                  </div>
                  <div className="join-info-item l-font16">
                    <h3>{t("currentRank")}</h3>
                    <p className="l-font40 l-blue impactMTStd l-mg15 l-lh0">
                      {team?.order}
                      <span className="l-font26 l-opaicty8">
                        /{team?.count}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="icon impact l-white l-uppercase l-lh0">
                  team
                </div>
                <div className="text l-font18 l-lh10">
                  <div className="join-text-item">
                    <p className="l-flex">
                      <em>
                        {t("teamName")}&nbsp;&nbsp;&nbsp;
                        <span className="l-gray6">{team?.name}</span>
                      </em>
                      <em>
                        {t("teamTag")}&nbsp;&nbsp;&nbsp;
                        <span className="l-gray6">{team?.shortName}</span>
                      </em>
                    </p>
                    <p className="l-flex">
                      <em>
                        {t("slogan")}&nbsp;&nbsp;&nbsp;
                        <span className="l-gray6">{team?.warCry}</span>
                      </em>
                    </p>
                    <div style={{ marginTop: "12px" }}>
                      <p>{t("matchTimes")}</p>
                      <div style={{ width: "100%" }}>
                        <div className="l-gray6">
                          {team?.entryTimes &&
                            entryTimesCompanents(
                              team?.entryTimes.map((t) => +t),
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="join-text-item">
                    <p className="l-flex">
                      <em>
                        {t("captainNickname")}&nbsp;&nbsp;&nbsp;
                        <span className="l-gray6">
                          {team?.leader.playerName}
                        </span>
                      </em>
                      <em>
                        {t("captainPower")}&nbsp;&nbsp;&nbsp;
                        <span className="l-gray6">{team?.leader.power}</span>
                      </em>
                    </p>
                    {isLeader && (
                      <>
                        {isCnSite && (
                          <>
                            <p className="l-flex">
                              <em>
                                队长手机号：
                                <span className="l-gray6">
                                  {team?.leader.phone}
                                </span>
                              </em>
                              <em>
                                备用联系人手机号：
                                <span className="l-gray6">
                                  {team?.leader.alternatesPhone}
                                </span>
                              </em>
                            </p>
                            {team?.leader.alternatesIMAccount && (
                              <p className="l-flex">
                                <em>
                                  备用联系人微信号：
                                  <span className="l-gray6">
                                    {team?.leader.alternatesIMAccount}
                                  </span>
                                </em>
                              </p>
                            )}
                          </>
                        )}
                        {!isCnSite && (
                          <>
                            <p className="l-flex">
                              <em>
                                {t("captainEmail")}&nbsp;&nbsp;&nbsp;
                                <span className="l-gray6">
                                  {team?.leader.email}
                                </span>
                              </em>
                              <em>
                                {t("alternateEmail")}&nbsp;&nbsp;&nbsp;
                                <span className="l-gray6">
                                  {team?.leader.alternatesEmail}
                                </span>
                              </em>
                            </p>
                            {getIMName(team?.leader.alternatesIMType) &&
                              team?.leader.alternatesIMAccount && (
                                <p className="l-flex">
                                  <em>
                                    {t("alternateIM")}&nbsp;&nbsp;&nbsp;
                                    <span className="l-gray6">
                                      {`(${getIMName(
                                        team?.leader.alternatesIMType,
                                      )})`}
                                      {team?.leader.alternatesIMAccount}
                                    </span>
                                  </em>
                                </p>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="me-teams l-mg50">
              <div className="l-font18">
                <b className="l-font24">{t("teamMembers")}</b>
                <em>
                  <span className="l-gray6">
                    &nbsp;&nbsp;({t("membersCountCondition")})
                  </span>
                </em>
              </div>
              <div
                className="me-teams-title l-flex"
                style={{ marginTop: "var(--size-20)" }}
              >
                <div className="left l-font18">
                  <em style={{ marginLeft: 0 }}>
                    {t("hasMembers")}{" "}
                    <span className="l-orange">{team?.members?.length}</span>
                  </em>
                  {isLeader && (
                    <a
                      style={{ cursor: "pointer" }}
                      className="l-orange quit-code-btn"
                      onClick={() => setOpenQuitRecords(true)}
                    >
                      {t("teamLog")}
                    </a>
                  )}
                </div>
                <div className="right">
                  {isLeader &&
                    team &&
                    team.members &&
                    team.members.length === 0 && (
                      <button
                        className="index-btn l-relative l-bold l-orange1 small"
                        onClick={() => setOpenDisbandConfirm(true)}
                      >
                        <img src="/img/btn9.png" />
                        <p>{t("diabandTeam")}</p>
                      </button>
                    )}
                  {isMember && team && (
                    <button
                      className="index-btn l-relative l-bold l-orange1 small"
                      onClick={() => setOpenQuitConfirm(true)}
                    >
                      <img src="/img/btn9.png" />
                      <p>{t("quitTeam")}</p>
                    </button>
                  )}
                  {isLeader && (
                    <button
                      className="index-btn l-relative l-bold l-white small"
                      onClick={() => setOpenInvition(true)}
                    >
                      <img src="/img/btn4.png" />
                      <p>{t("inviteMembers")}</p>
                    </button>
                  )}
                  {!isMember && !isLeader && (
                    <button
                      className="index-btn l-relative l-bold l-white small"
                      disabled={isTeamFull}
                      onClick={onJoinTeamClick}
                    >
                      <img src="/img/btn4.png" />

                      <p>
                        {isTeamFull && t("teamIsFull")}
                        {!isTeamFull && t("joinTeam")}
                      </p>
                    </button>
                  )}
                </div>
              </div>
              <div className="me-teams-cont manage l-mg20">
                <div className="me-teams-item l-flex l-font18 l-white">
                  <div className="left">{t("nickname")}</div>
                  <div className="right">{t("power")}</div>
                  {isLeader && <div className="manage">{t("manage")}</div>}
                </div>
                {team && (!team.members || team?.members?.length === 0) && (
                  <div
                    className="me-teams-item l-font18"
                    style={{ textAlign: "center", color: "#999" }}
                  >
                    <div>{t("noMembers")}</div>
                  </div>
                )}
                {team &&
                  team.members &&
                  team.members.length > 0 &&
                  team.members.map((member, index) => (
                    <div
                      className="me-teams-item l-flex l-font18"
                      key={`rok:member-${index}`}
                    >
                      <div className="left">
                        <div className="lines lines1">{member.playerName}</div>
                      </div>
                      <div className="right l-font20 l-bold l-orange">
                        <div className="lines lines1">{member.power}</div>
                      </div>
                      {isLeader && (
                        <div className="manage">
                          <a
                            style={{ cursor: "pointer" }}
                            className="remove-btn"
                            onClick={(e) => openRemoveMemberConfirm(e, member)}
                          >
                            <div className="lines lines1">{t("kick")}</div>
                          </a>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* <!--右侧联系方式--> */}
          {/* {isCnSite && isLeader && (
            <aside className="manage-fixed">
              <div className="accounts l-center">
                <div className="img">
                  <img src="/img/ewm.jpg" />
                </div>
                <p className="name">扫码添加官方公众号</p>
              </div>
              <div className="qq l-flex l-mg30">
                <div className="img">
                  <img src="/img/qq.png" />
                </div>
                <div className="text l-lh1">
                  <h3 className="num l-font18 l-bold l-orange">574832622</h3>
                  <p>官方赛事QQ群</p>
                </div>
              </div>
            </aside>
          )} */}
        </div>
      </section>
      {team && isLeader && (
        <>
          <QuitHistoryModal
            teamId={team.id}
            open={openQuitRecords}
            onClose={() => setOpenQuitRecords(false)}
          />

          <InvitionModal
            open={openInvition}
            code={team.inviteCode}
            team={team}
            onClose={() => setOpenInvition(false)}
          />
        </>
      )}

      <ConfirmModal
        title={t("kickConfirmTitle")}
        showOk
        showCancel
        open={openRemoveConfirm}
        onOk={handleRemoveMemberConfirm}
        onCancel={() => setOpenRemoveConfirm(false)}
        onClose={() => setOpenRemoveConfirm(false)}
      >
        <div
          className="text l-font18 l-center"
          style={{ marginTop: "var(--size-60)" }}
        >
          {t("kickConfirm", {
            nickname: currentMember?.playerName,
            power: currentMember?.power,
          })}
        </div>
      </ConfirmModal>
      <ConfirmModal
        title={t("diabandConfirm")}
        showOk
        showCancel
        open={openDisbandConfirm}
        onOk={handleDisbandConfirm}
        onCancel={() => setOpenDisbandConfirm(false)}
        onClose={() => setOpenDisbandConfirm(false)}
      >
        <div
          className="text l-font18 l-center"
          style={{ marginTop: "var(--size-60)" }}
        >
          {t("disbandConfirmContent")}
        </div>
      </ConfirmModal>
      <ConfirmModal
        title={t("quitConfirm")}
        showOk
        showCancel
        open={openQuitConfirm}
        onOk={handleQuitTeam}
        onCancel={() => setOpenQuitConfirm(false)}
        onClose={() => setOpenQuitConfirm(false)}
      >
        <div
          className="text l-font18 l-center"
          style={{ marginTop: "var(--size-60)" }}
        >
          {t("quitConfirmContent")}
        </div>
      </ConfirmModal>
      {team && (
        <JoinFormModal
          team={team}
          open={openJoinConfirm}
          onClose={handleJoined}
        />
      )}
    </main>
  )
}
