import { Link, useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { Team } from "@/types"
import { getMyTeamBriefInfo, getTeams } from "@/services/team.service"
import { GAME_REGION } from "@/enums"
import { useAppSelector } from "@/hooks"
import { selectUser } from "@/stores/auth.slice"
import ReactPaginate from "react-paginate"
import { t } from "i18next"

export function AllTeam() {
  const [page, setPage] = useState(1)
  const size = 20
  const [teams, setTeams] = useState<Team[] | []>([])
  const [pageCount, setPageCount] = useState(0)

  const { lng } = useParams()

  const isCnSite = import.meta.env.VITE_INSTANCE === "cn"
  const region = isCnSite ? GAME_REGION.CHINA : GAME_REGION.INTERNATIONAL

  useEffect(() => {
    getTeams({
      page,
      size,
      region,
    }).then((res) => {
      const { total, teams } = res
      setTeams(teams)
      if (total && total > size) {
        setPageCount(Math.ceil(total / size))
      }
      const headAnchor = document.getElementById("teams")
      if (headAnchor) headAnchor.scrollIntoView({ behavior: "smooth" })
    })
  }, [page])

  const [hasTeam, setHasTeam] = useState(false)
  const currentUser = useAppSelector(selectUser)
  const [myTeam, setMyTeam] = useState<Team | null>(null)
  useEffect(() => {
    if (!currentUser) setHasTeam(false)
    else if (currentUser.teamId) {
      setHasTeam(true)
      getMyTeamBriefInfo().then((teamRes) => {
        if (teamRes && teamRes.id) setMyTeam(teamRes)
      })
    } else setHasTeam(false)
  }, [currentUser])

  const onPageChange = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected + 1)
  }

  return (
    <main>
      <section className="lh-banner ban l-relative">
        <img src="/img/ban.png" />
      </section>
      <section className="lh-other two">
        <div className="l-container" id="teams">
          <div className="other-cont">
            <div className="index-title l-relative">
              <img src="/img/bicon3.png" />
              <div className="cont">
                <div>
                  <h3 className="name l-font70 honghuang">{t("teams")}</h3>
                </div>
              </div>
            </div>
            <div className="teams-info l-mg20">
              <div className="teams-info-top l-flex l-font18">
                <div className="left">
                  <img src="/img/tip1.png" />
                  <span>{t("refresh")}</span>
                </div>
                <div className="right">
                  <div className="l-flex" style={{ alignItems: "center" }}>
                    {currentUser && !currentUser.teamId && (
                      <Link
                        to={`/${lng}/enroll`}
                        className="index-btn l-relative l-bold l-white small l-font16"
                      >
                        <img src="/img/btn4.png" />
                        <p>{t("createTeam")}</p>
                      </Link>
                    )}
                    {currentUser && currentUser.teamId && myTeam && (
                      <>
                        {t("myTeamsRank")}:{"  "}
                        <span
                          className="l-orange"
                          style={{ marginRight: "12px" }}
                        >
                          {myTeam.order && myTeam.order >= 100
                            ? t("unranked")
                            : myTeam.order}
                        </span>
                        <Link
                          to={`/${lng}/team/${myTeam.maskId}`}
                          className="index-btn l-relative l-bold l-white small l-font16"
                        >
                          <img src="/img/btn4.png" />
                          <p>{t("viewMyTeam")}</p>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="teams-info-bottom">
                <div className="teams-item l-flex l-white l-font22 l-bold">
                  <div className="sort">{t("rank")}</div>
                  <div className="icon">{t("emblem")}</div>
                  <div className="name">{t("teamName")}</div>
                  <div className="nick">{t("captain")}</div>
                  <div className="slogan">{t("slogan")}</div>
                  <div className="bellipotent">{t("totalPower")}</div>
                </div>
                <div>
                  {(!teams || teams.length === 0) && (
                    <div
                      className="teams-item l-font18 l-bold"
                      style={{
                        height: "63px",
                        lineHeight: "63px",
                        textAlign: "center",
                        color: "#999",
                      }}
                    >
                      {t("noTeam")}
                    </div>
                  )}
                  {teams &&
                    teams.length > 0 &&
                    teams.map((team, index) => (
                      <Link
                        to={`/${lng}/team/${team?.maskId}`}
                        key={`rok:team-${index}`}
                      >
                        <div className="teams-item l-flex l-font18 l-bold">
                          <div className="sort l-font22">
                            {index === 0 && page === 1 && (
                              <img src="/img/icon9.png" />
                            )}
                            {index === 1 && page === 1 && (
                              <img src="/img/icon10.png" />
                            )}
                            {page === 1 && index > 1 && index + 1}
                            {page > 1 && size * (page - 1) + index + 1}
                          </div>
                          <div className="icon">
                            <img
                              src={`/img/guidon/${team.guidon}.png`}
                              style={{ width: "var(--size-35)" }}
                            />
                          </div>
                          <div className="name">
                            <div className="lines lines1">{team.name}</div>
                          </div>
                          <div className="nick">
                            <div className="lines lines1">
                              {team.leadersName}
                            </div>
                          </div>
                          <div className="slogan">
                            <div className="lines lines1">{team.warCry}</div>
                          </div>
                          <div className="bellipotent l-font20 l-orange l-bold">
                            <div className="lines lines1">
                              {team.totalPower}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
            <ReactPaginate
              pageCount={pageCount}
              containerClassName="list-page l-flex l-mg80"
              activeClassName="on"
              disabledClassName="paginate_disabled"
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              onPageChange={onPageChange}
              breakLabel="…"
              previousLabel={t("prev")}
              nextLabel={t("next")}
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </section>
    </main>
  )
}
