import { t } from "i18next"
import { useState, useEffect, MouseEvent } from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import schedules from "@/datas/schedules.json"
import Videos from "@/datas/videos.json"

export const OverviewTab = () => {
  const { lng } = useParams()

  const playVideo = (e: MouseEvent, src: string) => {
    e.preventDefault()
    //@ts-ignore
    _app.videoLayer(src)
  }

  const MVideo = Videos.find((v)=>v.id===1)!

  const [videoSrc, setVideoSrc] = useState(MVideo.src!.en)

  useEffect(() => {
    switch (lng) {
      case "zh-CN":
        setVideoSrc(MVideo.src!["zh-CN"])
        break
      case "zh-TW":
        setVideoSrc(MVideo.src!["zh-TW"] as string)
        break
      case "ko":
        setVideoSrc(MVideo.src!.ko as string)

        break
      case "vi":
        setVideoSrc(MVideo.src!.vi as string)
        break
      case "ja":
        setVideoSrc(MVideo.src!.ja as string)
        break
      case "es":
        setVideoSrc(MVideo.src!.es as string)
        break
      case "pt":
        setVideoSrc(MVideo.src!.pt as string)
        break
      case "ar":
        setVideoSrc(MVideo.src!.ar as string)
        break
      case "ru":
        setVideoSrc(MVideo.src!.ru as string)
        break
      default:
        setVideoSrc(MVideo.src!.en)
    }
  }, [lng])

  const dateFormatter = (date: string) => {
    const [month, day] = date.split(".")
    return `${day}/${month}`
  }

  const getScheduleDate = (date: string) => {
    if (lng === "es" || lng === "fr" || lng === "de") {
      if (date.includes("~")) {
        const [start, end] = date.split("~")
        return dateFormatter(start) + "~" + dateFormatter(end)
      } else {
        const [month, day] = date.split(".")
        return `${day}/${month}`
      }
    } else return date
  }

  return (
    <>
      <section className="index-dynamic index-page" id="Dynamic">
        <div className="l-container">
          <div className="dynamic-cont l-mg50 bannerBox l-relative">
            <div className="dynamic-banner l-banner l-overflow l-relative">
              <ul className="swiper-wrapper bd">
                <li className="swiper-slide">
                  <div className="dynamic-video l-relative">
                    <img className="bg" src={`/img/${lng}/video_cover.jpg`} />
                    <a
                      href="#"
                      className="video-btn"
                      id="video-trigger"
                      onClick={(e) => playVideo(e, videoSrc)}
                    >
                      <img src="/img/player1.png" />
                      <img src="/img/player2.png" />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section
        className="index-system l-mg50 index-page l-relative"
        id="System"
      >
        <div className="l-container">
          <div className="index-title l-relative">
            <img src="/img/icon1.png" />
            <div className="cont">
              <div>
                <h3 className="name l-font60 honghuang">{t("schedule")}</h3>
              </div>
            </div>
          </div>
          <div className="system-cont l-mg80 l-relative">
            <div className="system-scroll">
              <ul className="system-item l-font20">
                {schedules.map((schedule, index) => (
                  <li key={`schedules-${index}`}>
                    <h3 className="time impact l-font36 l-orange">
                      {schedule.date.includes("\n") && (
                        <>
                          {getScheduleDate(schedule.date.split("\n")[0])}
                          <br />
                          {getScheduleDate(schedule.date.split("\n")[1])}
                        </>
                      )}
                      {!schedule.date.includes("\n") &&
                        getScheduleDate(schedule.date)}
                    </h3>
                    <i>
                      <span></span>
                    </i>
                    <p className="name l-bold">
                      <pre>{t(schedule.event)}</pre>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="index-rule l-mg80 index-page" id="Rule">
        <div className="l-container">
          <div className="index-title l-relative">
            <img src="/img/icon1.png" />
            <div className="cont">
              <div>
                <h3 className="name l-font60 honghuang">{t("rules")}</h3>
              </div>
            </div>
          </div>
          <div className="rule-cont l-relative l-mg120">
            <div className="rule-cont-info l-flex l-relative">
              <div className="icon impact l-uppercase">Competition Rules</div>
              <div className="left">
                <img src="/img/role.png" />
              </div>
              <div className="right">
                <div className="cont l-font18 l-lh6 lines">
                  <pre>{t("rulesBrief")}</pre>
                </div>
                <div className="more l-mg40">
                  <Link
                    to={`/${lng}/rules`}
                    className="index-btn l-relative l-bold l-white l-font24"
                  >
                    <img src="/img/btn1.png" />
                    <p>{t("learnMore")}</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="index-prize l-mg120 index-page" id="Prize">
        <div className="l-container">
          <div className="index-title l-relative">
            <img src="/img/icon1.png" />
            <div className="cont">
              <div>
                <h3 className="name l-font60 honghuang">{t("rewards")}</h3>
              </div>
            </div>
          </div>
          <div className="prize-cont1 l-relative l-mg90">
            <div className="bg">
              <img src="/img/rewards_bg.png" />
            </div>
            <div className="icon">
              <img src={`/img/${lng}/rewards.png`} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
