import { useAppDispatch, useAppSelector } from "@/hooks"
import { useEffect, useMemo, useRef, useState } from "react"
import classNames from "classnames"
import { CreateTeamTimeSelect } from "./CreatTeamTimeSelect"
import { isValidIDNumber } from "./idNumberChecker"
import { useCountDown } from "@minax/countdown"
import { sendEmailCaptcha, sendPhoneCaptcha } from "@/services/auth.service"
import { createTeam, updateTeam } from "@/services/team.service"
import { selectUser, updateUserTeamInfo } from "@/stores/auth.slice"
import { useNavigate, useParams } from "react-router"
import { showModal } from "./Modal"
import { t } from "i18next"
import { ConfirmModal } from "./ConfirmModal"

type Props = {
  shouldScroll: boolean
  tid?: number
  data?: any
}

export function CreateTeamForm(props: Props) {
  useEffect(() => {
    // @ts-ignore
    _app.init()
  }, [])

  const { tid, data } = props

  const currentUser = useAppSelector(selectUser)

  const form = useRef(null)
  const [selectedGuidon, setSelectedGuidon] = useState<number>(-1)
  const [timeType, setTimeType] = useState<0 | 1 | 2>(0)
  const [selectedTimes, setSelectedTimes] = useState<string[]>()
  const [isInvitedOnly, setIsInvitedOnly] = useState(0)

  useEffect(() => {
    if (data && data.entryTimes) setSelectedTimes(data.entryTimes)
  }, [data])

  const onTimeSelected = (index: number, value: string) => {
    setSelectedTimes((times) => {
      if (times) {
        const _t = [...times]

        _t[index] = value

        return _t
      }

      return [value]
    })
  }
  const [openConfirm, setOpenConfirm] = useState(false)
  const isCnSite = import.meta.env.VITE_INSTANCE === "cn"
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const imTypes = useMemo(() => {
    if (isCnSite) {
      return ["微信"]
    }

    return ["Discord", "WhatsApp", "Kakao", "Line", "Zalo"]
  }, [isCnSite])

  const timesFilter = (time: string | undefined) => {
    if (!time) return false
    if (time == "") return false

    return true
  }

  const isTimesSelected = (times: string[] | [] | undefined) => {
    if (!times) return false
    const filterdTimes = times.filter((time) => timesFilter(time))
    if (filterdTimes.length < 1) return false

    return true
  }

  const rules: { [key: string]: (value?: any) => undefined | string } = {
    teamName: (value?: string) => {
      if (!value?.trim()) {
        return t("requireTeamName")
      }

      if (value?.trim().length < 5 || value?.trim().length > 20) {
        return t("char5-20")
      }

      return undefined
    },
    shortName: (value?: string) => {
      if (!value?.trim()) {
        return t("requireTeamTag")
      }

      if (value?.trim().length < 3 || value?.trim().length > 4) {
        return t("char3-4")
      }

      return undefined
    },
    warCry: (value?: string) => {
      if (!value?.trim()) {
        return t("requireSlogan")
      }

      if (value?.trim().length > 200) {
        return t("overCharLimit")
      }

      return undefined
    },
    guidon: (value?: string) => {
      if (!value?.trim() || +value?.trim() < 1) {
        return t("requireGuidon")
      }

      return undefined
    },
    captcha: (value?: string) => {
      if (!value?.trim()) {
        return t("needCaptcha")
      }

      return undefined
    },
    entryTimes: (value?: string[]) => {
      if (!isTimesSelected(value)) {
        return t("requireMatchTimes")
      }

      return undefined
    },
    realname: (value?: string) => {
      if (!value?.trim()) {
        return t("requireRealName")
      }

      return undefined
    },
    address: (value?: string) => {
      if (!value?.trim()) {
        return t("requireAddress")
      }

      return undefined
    },
    IDNumber: (value?: string) => {
      if (!isCnSite) {
        return undefined
      }

      if (!value?.trim()) {
        return "身份证号必须填写"
      }

      if (!isValidIDNumber(value?.trim())) {
        return "身份证号格式错误"
      }

      return undefined
    },
    email: (value?: string) => {
      // if (isCnSite) {
      //   return undefined
      // }

      if (!value?.trim()) {
        return t("requireEmail")
      }

      const emailReg =
        /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/

      if (!emailReg.test(value?.trim())) {
        return t("inputEmail")
      }

      return undefined
    },
    phone: (value?: string) => {
      if (!isCnSite) {
        return undefined
      }

      if (!value?.trim()) {
        return "手机号必须填写"
      }

      if (!/^1[3456789]\d{9}$/.test(value?.trim())) {
        return "手机号格式错误"
      }

      return undefined
    },
    alternatesPhone: (value?: string) => {
      if (!isCnSite) {
        return undefined
      }

      if (!value?.trim()) {
        return "备用手机号必须填写"
      }

      if (!/^1[3456789]\d{9}$/.test(value?.trim())) {
        return "备用手机号格式错误"
      }

      return undefined
    },
    alternatesEmail: (value?: string) => {
      if (isCnSite) {
        return undefined
      }

      if (!value?.trim()) {
        return t("requireAlternateEmail")
      }

      const emailReg =
        /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/

      if (!emailReg.test(value?.trim())) {
        return t("invalidAlternateEmail")
      }

      return undefined
    },
  }
  const { shouldScroll } = props
  useEffect(() => {
    if (shouldScroll) {
      const title = document.getElementById("title")
      title?.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [shouldScroll])

  const guidons = Array.from({ length: 16 }, (_, index) => {
    return {
      value: index + 1,
      src: `/img/guidon/${index + 1}.png`,
    }
  })

  const verifyData = (payload: { [key: string]: string | string[] }) => {
    const result: { [key: string]: string } = {}
    let pass = true

    Object.entries(payload).forEach(([key, value]) => {
      if (typeof rules[key] === "function") {
        const msg = rules[key](value)

        if (msg) {
          pass = false
          result[key] = msg
        }
      }
    })
    if (!isCnSite) {
      const { IMType, IMAccount, alternatesIMType, alternatesIMAccount } =
        payload

      if (IMType == "" && IMAccount == "") {
        result.leaderIM = ""
      } else if (!IMType || !IMAccount) {
        pass = false
        result.leaderIM = t("IMTips")
      } else result.leaderIM = ""

      if (alternatesIMType == "" && alternatesIMAccount == "") {
        result.alternateIM = ""
      } else if (!alternatesIMType || !alternatesIMAccount) {
        pass = false
        result.alternateIM = t("IMTips")
      } else result.alternateIM = ""
    }

    setErrors(result)

    return pass
  }

  const onFormChange = (e: any) => {
    const { name, value } = e.target
    if (!isCnSite) {
      if (name === "IMType" || name === "IMAccount") {
        if (form.current) {
          const data = new FormData(form.current as HTMLFormElement)
          const IMType = data.get("IMType")
          const IMAccount = data.get("IMAccount")
          if (IMType == "" && IMAccount == "")
            setErrors({ ...errors, leaderIM: "" })
          else if (!IMType || !IMAccount)
            setErrors({ ...errors, leaderIM: t("IMTips") })
          else {
            setErrors({ ...errors, leaderIM: "" })
          }
        }
      }

      if (name === "alternatesIMType" || name === "alternatesIMAccount") {
        if (form.current) {
          const data = new FormData(form.current as HTMLFormElement)
          const alternatesIMType = data.get("alternatesIMType")
          const alternatesIMAccount = data.get("alternatesIMAccount")
          if (alternatesIMType == "" && alternatesIMAccount == "")
            setErrors({ ...errors, alternateIM: "" })
          else if (!alternatesIMType || !alternatesIMAccount)
            setErrors({ ...errors, alternateIM: t("IMTips") })
          else {
            setErrors({ ...errors, alternateIM: "" })
          }
        }
      }
    }

    if (typeof rules[name] === "function") {
      const result = rules[name](
        name === "entryTimes" ? value?.split(",") : value.trim(),
      )
      const newErrors: any = { ...errors }

      if (result) {
        newErrors[name] = result
      } else {
        newErrors[name] = undefined
      }

      setErrors(newErrors)
    }
  }

  useEffect(() => {
    const newErrors: any = { ...errors }

    newErrors.guidon = undefined

    setErrors(newErrors)
  }, [selectedGuidon])

  useEffect(() => {
    const newErrors: any = { ...errors }
    if (
      selectedTimes?.length === 3 &&
      selectedTimes?.every((item) => item !== "")
    ) {
      newErrors.entryTimes = undefined
    } else {
      newErrors.entryTimes = rules.entryTimes(selectedTimes)
    }
    setErrors(newErrors)
  }, [selectedTimes])

  const [sendPhoneCaptchaRest, resetSendPhoneCaptcha] = useCountDown(
    "sendCaptchaWithAuthService",
    {
      total: 60,
      lifecycle: "session",
    },
  )

  const onVerifyCodeClicked = () => {
    if (form.current) {
      const data = new FormData(form.current as HTMLFormElement)
      const key = isCnSite ? "phone" : "email"
      const value = data.get(key) // 这个value就是电话号码或者是邮箱
      const result = isCnSite ? rules.phone(value) : rules.email(value)

      const newErrors: any = { ...errors }
      if (result) {
        newErrors[key] = result
      } else {
        newErrors[key] = undefined

        if (value) {
          resetSendPhoneCaptcha()
          isCnSite
            ? sendPhoneCaptcha(value.toString().trim())
            : sendEmailCaptcha(value.toString().trim())
        }
      }
      setErrors(newErrors)
    }
  }

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { lng } = useParams()

  const [submitDisabled, setSubmitDisabled] = useState(false)

  const onSubmitClick = () => {
    if (form.current) {
      const data = new FormData(form.current as HTMLFormElement)
      const payload: { [key: string]: string | string[] } = {}
      Array.from(data.entries()).forEach(([key, value]) => {
        if (!(value instanceof File)) {
          payload[key] = key === "entryTimes" ? value.split(",") : value.trim()
        }
      })

      if (verifyData(payload)) {
        setSubmitDisabled(true)
        setOpenConfirm(true)
      }
    }
  }

  const cancelConfirm = () => {
    setSubmitDisabled(false)
    setOpenConfirm(false)
  }

  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    setOpenConfirm(false)
    if (form.current) {
      const data = new FormData(form.current as HTMLFormElement)
      const payload: { [key: string]: string | string[] } = {}
      Array.from(data.entries()).forEach(([key, value]) => {
        if (!(value instanceof File)) {
          payload[key] = key === "entryTimes" ? value.split(",") : value.trim()
        }
      })
      if (verifyData(payload)) {
        if (Array.isArray(payload["entryTimes"])) {
          payload["entryTimes"] = payload["entryTimes"].filter(
            (time: string | undefined) => timesFilter(time),
          )
        }
        if (tid) {
          setLoading(true)
          updateTeam(tid, payload).then((res) => {
            if (res && res.id) {
              navigate(`/${lng}/team/${res.maskId}`)
            }
            setLoading(false)
            setSubmitDisabled(false)
          })
        } else {
          setLoading(true)
          createTeam(payload).then((res) => {
            if (res && res.id) {
              dispatch(
                updateUserTeamInfo({ teamId: res.id, teamMaskId: res.maskId }),
              )
              showModal({
                title: t("teamCreated"),
                message: t("createSuccess"),
                onClose: () => navigate(`/${lng}/team/${res.maskId}`),
              })
            }
            setLoading(false)
            setSubmitDisabled(false)
          })
        }
      }
    }
  }

  return (
    <>
      <main>
        <section className="lh-banner ban l-relative">
          <img src="/img/ban.png" />
        </section>
        <section className="lh-other">
          <div className="l-container">
            <div className="other-cont">
              <div className="border"></div>
              <div className="index-title l-relative">
                <img src="/img/bicon3.png" />
                <div className="cont">
                  <div>
                    <h3 className="name l-font70 honghuang" id="title">
                      {tid ? "更新队伍信息" : t("createTeam")}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="create-tip l-flex l-font16 l-mg90 l-gray6">
                <div>
                  <img src="/img/tip1.png" />
                  <span>{t("requireFields")}</span>
                </div>
              </div>
              <form
                className="create-form"
                id="team_creation_form"
                ref={form}
                onSubmit={onSubmitClick}
                onChange={onFormChange}
              >
                <div className="join-form-item">
                  <ul className="join-input l-grid-2 l-font16">
                    <li className={classNames({ error: !!errors.teamName })}>
                      <h3 className="name l-font20">
                        {t("teamName")}
                        <span className="l-red">* </span>
                        <span className="l-gray6 l-font16">
                          {`(${t("char5-20")})`}
                        </span>
                      </h3>
                      <div className="cont l-relative">
                        <input
                          type="text"
                          disabled={loading}
                          maxLength={20}
                          placeholder={t("requireTeamName")}
                          name="teamName"
                          defaultValue={data && data.teamName}
                        />
                        {/* <span className="maxnum l-gray9">15</span> */}
                        {errors.teamName && (
                          <p className="error-tip l-red">{errors.teamName}</p>
                        )}
                      </div>
                    </li>
                    <li className={classNames({ error: !!errors.shortName })}>
                      <h3 className="name l-font20">
                        {t("teamTag")}
                        <span className="l-red">* </span>
                        <span className="l-gray6 l-font16">
                          {`(${t("char3-4")})`}
                        </span>
                      </h3>
                      <div className="cont l-relative">
                        <input
                          type="text"
                          maxLength={4}
                          disabled={loading}
                          placeholder={t("requireTeamTag")}
                          name="shortName"
                          defaultValue={data && data.shortName}
                        />
                        {/* <span className="maxnum l-gray9">4</span> */}
                        {errors.shortName && (
                          <p className="error-tip l-red">{errors.shortName}</p>
                        )}
                      </div>
                    </li>
                    <li
                      className={classNames("w100", { error: !!errors.warCry })}
                    >
                      <h3 className="name l-font20">
                        {t("slogan")}
                        <span className="l-red">* </span>
                        <span className="l-gray6 l-font16">{`(${t(
                          "char1-200",
                        )})`}</span>
                      </h3>
                      <div className="cont l-relative">
                        <textarea
                          placeholder={t("requireSlogan")}
                          name="warCry"
                          defaultValue={data && data.warCry}
                        ></textarea>
                        {/* <span className="maxnum l-gray9">200</span> */}
                        {errors.warCry && (
                          <p className="error-tip l-red">{errors.warCry}</p>
                        )}
                      </div>
                    </li>
                    <li
                      className={classNames("w100", { error: !!errors.guidon })}
                    >
                      <h3 className="name l-font20">
                        {t("guidon")}
                        <span className="l-red">* </span>
                      </h3>
                      <div className="cont l-relative">
                        <div className="teams-icons l-relative bannerBox">
                          <div
                            className="teams-icons-banner l-banner l-overflow"
                            data-view="9"
                            data-webview="3"
                          >
                            <div className="swiper-wrapper bd">
                              <input
                                type="hidden"
                                value={selectedGuidon}
                                name="guidon"
                                defaultValue={data && data.guidon}
                              />
                              {guidons.map((guidon, i) => (
                                <div
                                  key={`guidon-${i}`}
                                  className={classNames("swiper-slide", {
                                    on: guidon.value === selectedGuidon,
                                  })}
                                >
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setSelectedGuidon(guidon.value)
                                    }
                                  >
                                    <img src={guidon.src} />
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="teams-icons-btn bannerBtn">
                            <a href="#" className="_tPrevs prevs">
                              <img src="/img/jtleft.png" />
                            </a>
                            <a href="#" className="_tNext nexts">
                              <img src="/img/jtright.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                      {errors.guidon && (
                        <p className="error-tip l-red">{errors.guidon}</p>
                      )}
                    </li>
                    <li
                      className={classNames("w100", {
                        error: !!errors.entryTimes,
                      })}
                    >
                      <h3 className="name l-font20">
                        {t("matchTimes")}
                        <span className="l-red">* </span>
                        <span className="l-gray6 l-font16">
                          {`(${t("matchTimesTips")})`}
                        </span>
                      </h3>
                      <div className="radio-group">
                        <span
                          className={classNames("radio-item", {
                            active: timeType === 0,
                          })}
                          onClick={() => setTimeType(0)}
                        >
                          UTC+0
                        </span>
                        <span
                          className={classNames("radio-item", {
                            active: timeType === 1,
                          })}
                          onClick={() => setTimeType(1)}
                        >
                          UTC+8
                        </span>
                        <span
                          className={classNames("radio-item", {
                            active: timeType === 2,
                          })}
                          onClick={() => setTimeType(2)}
                        >
                          UTC-7
                        </span>
                      </div>
                      <div className="cont l-relative">
                        <input
                          type="hidden"
                          name="entryTimes"
                          value={selectedTimes}
                          defaultValue={data && data.entryTimes}
                        />
                        <div className="create-select l-grid-3 l-flex">
                          <CreateTeamTimeSelect
                            index={0}
                            disabled={loading}
                            timeType={timeType}
                            selected={selectedTimes}
                            onChange={onTimeSelected}
                          />
                          <CreateTeamTimeSelect
                            index={1}
                            disabled={loading}
                            timeType={timeType}
                            selected={selectedTimes}
                            onChange={onTimeSelected}
                          />
                          <CreateTeamTimeSelect
                            index={2}
                            disabled={loading}
                            timeType={timeType}
                            selected={selectedTimes}
                            onChange={onTimeSelected}
                          />
                        </div>
                        {errors.entryTimes && (
                          <p className="error-tip l-red">{errors.entryTimes}</p>
                        )}
                      </div>
                    </li>
                    <li className="w100">
                      <h3 className="name l-font20">
                        {t("invatationLimit")}
                        <span className="l-red">* </span>
                        <p className="l-gray6 l-font16">
                          {`(${t("invatationLimitTips")})`}
                        </p>
                      </h3>
                      <div className="cont l-relative">
                        <div className="lh-radio l-flex">
                          <input
                            type="hidden"
                            name="isInvitedOnly"
                            disabled={loading}
                            value={isInvitedOnly}
                            defaultValue={data && data.isInvitedOnly}
                          />
                          <div
                            className={classNames("item", {
                              on: isInvitedOnly === 0,
                            })}
                            onClick={() => setIsInvitedOnly(0)}
                          >
                            <i></i>
                            <span>{t("no")}</span>
                          </div>
                          <div
                            className={classNames("item", {
                              on: isInvitedOnly === 1,
                            })}
                            onClick={() => setIsInvitedOnly(1)}
                          >
                            <i></i>
                            <span>{t("yes")}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="join-form-item">
                  <h3 className="title l-font30 l-bold">{t("cantactInfo")}</h3>
                  <ul className="join-input l-grid-2 l-font16">
                    <li>
                      <h3 className="name l-font20">
                        {t("captainNickname")}
                        <span className="l-red">* </span>
                        <span className="l-gray6 l-font16">
                          {`(${t("nicknameTips")})`}
                        </span>
                      </h3>
                      <div className="cont l-relative">
                        <input
                          type="text"
                          disabled
                          value={currentUser?.playerName}
                        />
                      </div>
                    </li>
                    <li className={classNames({ error: !!errors.realname })}>
                      <h3 className="name l-font20">
                        {t("captainRealname")}
                        <span className="l-red">* </span>
                      </h3>
                      <div className="cont l-relative">
                        <input
                          type="text"
                          placeholder={t("realnamePlaceholder")}
                          maxLength={18}
                          disabled={loading}
                          name="realname"
                          defaultValue={data && data.realname}
                        />
                        {errors.realname && (
                          <p className="error-tip l-red">{errors.realname}</p>
                        )}
                      </div>
                    </li>
                    {isCnSite && (
                      <li className={classNames({ error: !!errors.phone })}>
                        <h3 className="name l-font20">
                          队长手机号<span className="l-red">* </span>
                        </h3>
                        <div className="cont l-relative">
                          <input
                            type="tel"
                            maxLength={11}
                            disabled={loading}
                            name="phone"
                            placeholder="请输入手机号"
                            defaultValue={data && data.phone}
                          />
                          {errors.phone && (
                            <p className="error-tip l-red">{errors.phone}</p>
                          )}
                        </div>
                      </li>
                    )}
                    {!isCnSite && (
                      <li className={classNames({ error: !!errors.email })}>
                        <h3 className="name l-font20">
                          {t("captainEmail")}
                          <span className="l-red">* </span>
                        </h3>
                        <div className="cont l-relative">
                          <input
                            type="email"
                            disabled={loading}
                            placeholder={t("requireCaptainEmail")}
                            name="email"
                            defaultValue={data && data.email}
                          />
                          {errors.email && (
                            <p className="error-tip l-red">{errors.email}</p>
                          )}
                        </div>
                      </li>
                    )}
                    <li className={classNames({ error: !!errors.captcha })}>
                      <h3 className="name l-font20">
                        {t("emailCaptcha")}
                        <span className="l-red">* </span>
                      </h3>
                      <div className="cont l-relative">
                        <input
                          type="text"
                          disabled={loading}
                          placeholder={t("emailCaptchaPlaceholder")}
                          maxLength={6}
                          name="captcha"
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            onVerifyCodeClicked()
                          }}
                          disabled={sendPhoneCaptchaRest > 0 || loading}
                          className="index-btn l-relative l-bold l-white small"
                        >
                          <img src="/img/btn2.png" />
                          <p>
                            {t("sendCode")}
                            {sendPhoneCaptchaRest > 0 &&
                              `(${sendPhoneCaptchaRest}s)`}
                          </p>
                        </button>
                        {errors.captcha && (
                          <p className="error-tip l-red">{errors.captcha}</p>
                        )}
                      </div>
                    </li>

                    {isCnSite && (
                      <li className={classNames({ error: !!errors.IDNumber })}>
                        <h3 className="name l-font20">
                          队长身份证号<span className="l-red">* </span>
                        </h3>
                        <div className="cont l-relative">
                          <input
                            type="text"
                            disabled={loading}
                            placeholder="请输入身份证号"
                            maxLength={18}
                            name="IDNumber"
                            defaultValue={data && data.IDNumber}
                          />
                          {errors.IDNumber && (
                            <p className="error-tip l-red">{errors.IDNumber}</p>
                          )}
                        </div>
                      </li>
                    )}
                    {isCnSite && (
                      <li className={classNames({ error: !!errors.email })}>
                        <h3 className="name l-font20">
                          队长邮箱<span className="l-red">* </span>
                        </h3>
                        <div className="cont l-relative">
                          <input
                            type="email"
                            disabled={loading}
                            placeholder="请输入邮箱地址"
                            name="email"
                            defaultValue={data && data.email}
                          />
                          {errors.email && (
                            <p className="error-tip l-red">{errors.email}</p>
                          )}
                        </div>
                      </li>
                    )}
                    <li className={classNames({ error: !!errors.address })}>
                      <h3 className="name l-font20">
                        {t("captainAddress")}
                        <span className="l-red">* </span>
                      </h3>
                      <div className="cont l-relative">
                        <input
                          type="text"
                          disabled={loading}
                          placeholder={t("requireCaptainAddress")}
                          name="address"
                          defaultValue={data && data.address}
                        />
                        {errors.address && (
                          <p className="error-tip l-red">{errors.address}</p>
                        )}
                      </div>
                    </li>

                    {isCnSite && (
                      <li
                        className={classNames({
                          error: !!errors.alternatesPhone,
                        })}
                      >
                        <h3 className="name l-font20">
                          备用联系人手机号<span className="l-red">* </span>
                        </h3>
                        <div className="cont l-relative">
                          <input
                            type="tel"
                            disabled={loading}
                            placeholder="请输入手机号"
                            maxLength={11}
                            name="alternatesPhone"
                            defaultValue={data && data.alternatesPhone}
                          />
                          {errors.alternatesPhone && (
                            <p className="error-tip l-red">
                              {errors.alternatesPhone}
                            </p>
                          )}
                        </div>
                      </li>
                    )}
                    {!isCnSite && (
                      <li
                        className={classNames({
                          error: !!errors.alternatesEmail,
                        })}
                      >
                        <h3 className="name l-font20">
                          {t("alternateEmail")}
                          <span className="l-red">* </span>
                        </h3>
                        <div className="cont l-relative">
                          <input
                            type="email"
                            disabled={loading}
                            placeholder={t("alternateEmail")}
                            name="alternatesEmail"
                            defaultValue={data && data.alternatesEmail}
                          />
                          {errors.alternatesEmail && (
                            <p className="error-tip l-red">
                              {errors.alternatesEmail}
                            </p>
                          )}
                        </div>
                      </li>
                    )}
                    <li className={classNames({ error: !!errors.leaderIM })}>
                      <h3 className="name l-font20">
                        {isCnSite ? "队长微信" : t("captainIM")}
                      </h3>
                      <div
                        className="cont l-relative"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          className="lh-select l-relative"
                          style={{ flex: 1 }}
                        >
                          <select
                            name="IMType"
                            style={{
                              borderRadius: "var(--size-10) 0 0 var(--size-10)",
                            }}
                            disabled={isCnSite || loading}
                            defaultValue={
                              isCnSite ? imTypes[0] : data && data.IMType
                            }
                          >
                            {!isCnSite && <option value="">Select</option>}
                            {imTypes.map((item, index) => (
                              <option
                                key={item}
                                value={isCnSite ? index : index + 1}
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                        <input
                          style={{
                            flex: 2,
                            borderRadius: "0 var(--size-10) var(--size-10) 0",
                            borderLeft: "none",
                          }}
                          type="text"
                          disabled={loading}
                          placeholder={
                            isCnSite ? "请输入队长微信号" : t("IMTips")
                          }
                          name="IMAccount"
                          defaultValue={data && data.IMAccount}
                        />
                        {errors.leaderIM && (
                          <p className="error-tip l-red">{errors.leaderIM}</p>
                        )}
                      </div>
                    </li>
                    <li className={classNames({ error: !!errors.alternateIM })}>
                      <h3 className="name l-font20">
                        {isCnSite ? "备用联系人微信" : t("alternateIM")}
                      </h3>
                      <div
                        className="cont l-relative"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          className="lh-select l-relative"
                          style={{ flex: 1 }}
                        >
                          <select
                            name="alternatesIMType"
                            style={{
                              borderRadius: "var(--size-10) 0 0 var(--size-10)",
                            }}
                            disabled={isCnSite || loading}
                            defaultValue={
                              isCnSite
                                ? imTypes[0]
                                : data && data.alternatesIMType
                            }
                          >
                            {!isCnSite && <option value="">Select</option>}
                            {imTypes.map((item, index) => (
                              <option
                                key={item}
                                value={isCnSite ? index : index + 1}
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                        <input
                          style={{
                            flex: 2,
                            borderRadius: "0 var(--size-10) var(--size-10) 0",
                            borderLeft: "none",
                          }}
                          type="text"
                          disabled={loading}
                          placeholder={
                            isCnSite ? "请输入备用联系人微信号" : t("IMTips")
                          }
                          name="alternatesIMAccount"
                          defaultValue={data && data.alternatesIMAccount}
                        />
                        {errors.alternateIM && (
                          <p className="error-tip l-red">
                            {errors.alternateIM}
                          </p>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="form-btn l-flex l-mg60">
                  <button
                    className="index-btn max l-relative l-bold l-white l-font32"
                    disabled={submitDisabled || loading}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      onSubmitClick()
                    }}
                  >
                    <img src="/img/btn10.png" />
                    <p>{tid ? "更新队伍" : t("createTeam")}</p>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <ConfirmModal
        open={openConfirm}
        title={t("createTeam")}
        showCancel
        showOk
        onCancel={cancelConfirm}
        onOk={() => onSubmit()}
        onClose={cancelConfirm}
      >
        <div className="l-font18 l-center l-mg30">
          <p>{t("createTips1")}</p>
          <p style={{ marginTop: "var(--size-10)" }}>{t("createTips2")}</p>
        </div>
      </ConfirmModal>
    </>
  )
}
