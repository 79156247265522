import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "@/stores"

interface ModalSlice {
  openLoginModal: boolean
  openCreateTeamModal: boolean
  openJoinTeamModal: boolean
  openQuitHistoryModal: boolean
  openInvitionModal: boolean
  openConfirmModal: boolean
}

const initialState: ModalSlice = {
  openLoginModal: false,
  openCreateTeamModal: false,
  openJoinTeamModal: false,
  openQuitHistoryModal: false,
  openInvitionModal: false,
  openConfirmModal: false,
}

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    toggleLoginModal: (state) => {
      state.openLoginModal = !state.openLoginModal
    },
  },
})

export const { toggleLoginModal } = modalSlice.actions

export const selectIsLoginModalOpen = (state: RootState) =>
  state.modal.openLoginModal

export default modalSlice.reducer
