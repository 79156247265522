import Modal from "./Modal"
import { ModalProps } from "./Modal/modalFrame"
import { t } from "i18next"

export const ConfirmModal = (
  props: ModalProps & {
    title: string
    showOk?: boolean
    showCancel?: boolean
    onOk?: () => void
    onCancel?: () => void
    okText?: string
    cancelText?: string
  },
) => {
  return (
    <Modal {...props} title={props.title}>
      {props.children}
      <div className="quit-layer-btn l-flex l-mg60">
        {props.showCancel && (
          <a
            style={{ cursor: "pointer" }}
            className="index-btn confirm l-relative l-bold l-orange1 l-font24"
            onClick={props.onCancel}
          >
            <img src="/img/btn8.png" />
            <p>{props.cancelText || t("cancel")}</p>
          </a>
        )}
        {props.showOk && (
          <a
            style={{ cursor: "pointer" }}
            className="index-btn confirm l-relative l-bold l-white l-font24"
            onClick={props.onOk}
          >
            <img src="/img/btn3.png" />
            <p>{props.okText || t("confirm")}</p>
          </a>
        )}
      </div>
    </Modal>
  )
}
