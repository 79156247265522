import http from "@/http"
import { Team } from "@/types"

interface CreateTeamDto {
  teamName: string
  shortName: string
  warCry: string
  guidon: number
  captcha: string
  entryTimes: string[]
  isInvitedOnly: number
  realname: string
  address: string
  IDNumber?: string
  email?: string
  phone?: string
  IMType?: number
  IMAccount?: string
  alternatesPhone?: string
  alternatesEmail?: string
  alternatesIMType?: number
  alternatesIMAccount?: string
}

interface GetTeamsParams {
  region: 0 | 1
  page: number
  size: number
}

interface GetTeamsRes {
  page: number
  size: number
  total: number
  teams: Team[] | []
}

interface NoDataRes {
  code: number
  msg: string
}

export interface QuitRecord {
  id: number
  tid: number
  uid: number
  playerName: string
  power: number
  quitTime: Date
  manner: "0" | "1"
}

interface QuitRecodsRes {
  code: number
  msg: string
  records: QuitRecord[] | []
  page: number
  size: number
  total: number
}

interface TeamBriefInfo extends Team {
  order: number
  count: number
}

export function createTeam(data: any): Promise<Team> {
  return http.post("/team", data)
}

export function getTeams(params: GetTeamsParams): Promise<GetTeamsRes> {
  return http.get("/team", null, { params })
}

export function getTeam(
  maskId: string,
  uid?: number,
): Promise<Team | undefined> {
  return uid
    ? http.get(`/team/${maskId}?uid=${uid}`)
    : http.get(`/team/${maskId}`)
}

export function getMyTeamBriefInfo(): Promise<TeamBriefInfo | undefined> {
  return http.get("/team/my/brief")
}

export function getTeamByInviteCode(params: {
  code: string
}): Promise<Team | null> {
  return http.get("/team/invite", null, { params })
}

export function updateTeam(id: number, data: any): Promise<Team | null> {
  return http.patch(`/team/${id}`, data)
}

export function changTeamInviteCode(id: number): Promise<string> {
  return http.patch(`/team/${id}/change_code`)
}

export function joinTeam(
  data: any,
  tid?: number,
  code?: string,
): Promise<NoDataRes> {
  return http.post("/team/join", { tid, code, data })
}

export function quitTeam(): Promise<NoDataRes> {
  return http.post("/team/quit")
}

export function removeTeamMember(mid: number): Promise<NoDataRes> {
  return http.post("/team/remove_member", { mid })
}

export function disbandTeam(): Promise<NoDataRes> {
  return http.post("/team/disband")
}

export function getTeamQuitRecords(
  tid: number,
  page: number,
  size: number,
): Promise<QuitRecodsRes> {
  return http.get("qr", null, { params: { tid, page, size } })
}
