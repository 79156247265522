import { GAME_REGION } from "./enums"

export function powerFormatter(
  powerValue: string | number | undefined,
): string {
  if (!powerValue) return ""
  else {
    const power =
      typeof powerValue !== "number" ? parseInt(powerValue) : powerValue
    if (power < 0) return ""
    else if (power < 1e4) return `${powerValue}`
    else if (power < 1e7) return `${Math.round(power / 1e3)}K`
    else if (power < 1e10) return `${Math.round(power / 1e6)}M`
    else return `${Math.round(power / 1e9)}B`
  }
}

export function copyTextToClipboard(text: string, callback: () => void) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text)
  } else {
    const textarea = document.createElement("textarea")
    document.body.appendChild(textarea)
    textarea.style.position = "fixed"
    textarea.style.clip = "rect(0 0 0 0)"
    textarea.style.top = "10px"

    textarea.value = text
    textarea.select()

    document.execCommand("copy", true)

    document.body.removeChild(textarea)
  }
  callback && callback()
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0")
}

export function formatDate(date: Date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("/") +
    " " +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(":")
  )
}

export function shortDateFormatter(date: Date) {
  return (
    [padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join(
      "/",
    ) +
    " " +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(":")
  )
}

const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

export const REGION = isCnSite ? GAME_REGION.CHINA : GAME_REGION.INTERNATIONAL

export const sliceArrayWithLength = (arr: any[], length: number) => {
  let index = 0
  const results = []

  while (index < arr.length) {
    results.push(arr.slice(index, (index += length)))
  }

  return results
}
