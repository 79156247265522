import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

import transZhCN from "./locales/zh-CN/trans.json"
import transEnUS from "./locales/en/trans.json"
import transAr from "./locales/ar/trans.json"
import transDe from "./locales/de/trans.json"
import transEs from "./locales/es/trans.json"
import transFr from "./locales/fr/trans.json"
import transJa from "./locales/ja/trans.json"
import transKo from "./locales/ko/trans.json"
import transPt from "./locales/pt/trans.json"
import transRu from "./locales/ru/trans.json"
import transVi from "./locales/vi/trans.json"
import transZhTW from "./locales/zh-TW/trans.json"

export const DEFAULT_LANGUAGE = isCnSite ? "zh-CN" : "en"
export const SUPPORTED_LANGUAGE = isCnSite
  ? ["zh-CN"]
  : ["en", "de", "ru", "fr", "pt", "es", "ar", "ja", "ko", "vi", "zh-TW"]

// the translations
const resources = {
  "zh-CN": {
    translation: transZhCN,
  },
  ar: {
    translation: transAr,
  },
  de: {
    translation: transDe,
  },
  en: {
    translation: transEnUS,
  },
  es: {
    translation: transEs,
  },
  fr: {
    translation: transFr,
  },
  ja: {
    translation: transJa,
  },
  ko: {
    translation: transKo,
  },
  pt: {
    translation: transPt,
  },
  ru: {
    translation: transRu,
  },
  vi: {
    translation: transVi,
  },
  "zh-TW": {
    translation: transZhTW,
  },
}

i18n.use(LanguageDetector).init({
  resources,
  fallbackLng: isCnSite ? "zh-CN" : "en",
  supportedLngs: SUPPORTED_LANGUAGE,

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18n
