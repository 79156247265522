import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import ModalFrame, { ModalProps } from "./modalFrame"

function Modal(props: ModalProps) {
  const { open, title } = props

  const [rootNode, setRootNode] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const getRootNode = document.getElementById("modal_root")

    if (open) {
      if (!getRootNode) {
        const modalWrap = document.createElement("div")
        modalWrap.setAttribute("id", "modal_root")
        document.body.appendChild(modalWrap)

        setRootNode(modalWrap)
      } else setRootNode(getRootNode)
    } else {
      if (rootNode) setRootNode(null)
      else getRootNode && document.body.removeChild(getRootNode)
    }
  }, [open, rootNode])

  return rootNode
    ? ReactDOM.createPortal(<ModalFrame {...props} />, rootNode)
    : null
}

export default React.memo(Modal)
