import { useState } from "react"
import { Agreement } from "@/components/Agreement"
import { CreateTeamForm } from "@/components/CreateTeamForm"

export function CreateTeam() {
  const [agree, setAgree] = useState(false)
  const [shouldScroll, setShouldScroll] = useState(false)
  const afterAgrer = () => {
    setAgree(true)
    setShouldScroll(true)
  }
  return agree ? (
    <CreateTeamForm shouldScroll={shouldScroll} />
  ) : (
    <Agreement afterAgree={afterAgrer} />
  )
}
