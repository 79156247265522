import { Navigate, useParams } from "react-router"
import { useAppSelector } from "@/hooks"
import { selectUser } from "@/stores/auth.slice"
import { ReactNode } from "react"

interface Props {
  children: ReactNode
}

function AuthGuardRoute({ children }: Props) {
  const user = useAppSelector(selectUser)
  const { lng } = useParams()

  if (!user) {
    return <Navigate to={`${lng}`} />
  }

  return children
}

export default AuthGuardRoute
