import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  HttpStatusCode,
} from "axios"
import { showModal } from "./components/Modal"
import { baseURL, signUpEndDate } from "./config"
import i18n, { t } from "i18next"
import { DEFAULT_LANGUAGE } from "./i18n"

// const baseURL =
//   process.env.NODE_ENV === "production"
//     ? process.env.BASE_API_URL
//     : "http://localhost:3000/api"

class Http {
  constructor() {
    this._axios = axios.create({
      baseURL,
    })

    this.initRequestInterceptors()
    this.initResponseInterceptors()
  }

  private _axios: AxiosInstance
  private static _instance: Http

  public static getInstance(): Http {
    this._instance || (this._instance = new Http())
    return this._instance
  }

  private initRequestInterceptors() {
    this._axios.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        let { method } = config
        const { url } = config
        method = method?.toUpperCase()
        if (
          (method === "POST" && url?.startsWith("/team")) ||
          (method === "PATCH" && url?.startsWith("/team"))
        ) {
          // const SignUpEndDate = new Date("September 5, 2023 00:00:00 GMT+08:00")
          if (Date.now() - signUpEndDate.valueOf() > 0) {
            throw new AxiosError(
              t("signUpEndsWarn"),
              HttpStatusCode.BadRequest.toString(),
            )
          }
        }
        const token = localStorage.getItem("token")
        if (token && !url?.includes("auth")) {
          config.headers.Authorization = `Bearer ${token}`
        }

        config.headers["x-lang"] = i18n.language || DEFAULT_LANGUAGE
        return config
      },
      (error: Error) => {
        console.error(error.message)
        return Promise.reject(error)
      },
    )
  }

  private initResponseInterceptors() {
    this._axios.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.headers["content-type"].includes("application/json")) {
          return "data" in response.data ? response.data.data : response.data
        } else {
          return response
        }
      },
      async (error: AxiosError) => {
        const title = t("error")

        if (!error.response || error.response.status === 503) {
          showModal({
            title,
            message: t("fullStatus"),
          })
        } else if (error.response.data) {
          console.log("error.response.data: ", error.response.data)

          let { code, msg } = error.response.data as {
            code: number
            msg: string
          }
          if (!code) {
            const { statusCode, message } = error.response.data as {
              statusCode: number
              message: string
            }
            code = statusCode
            msg = message
          }

          if (code === 401003 || code === 401004) {
            // 登录过期或无效token
            localStorage.removeItem("token")
            localStorage.removeItem("user")
          }
          showModal({
            title,
            message: `${msg}`,
          })
        } else {
          showModal({
            title,
            message: `${error.response?.statusText}(status:${error.response?.status})`,
          })
        }
      },
    )
  }

  public request<T, R, D>(config: AxiosRequestConfig<D>): Promise<R> {
    return new Promise((resolve, reject) => {
      this._axios
        .request<T, R>(config)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public get<T, R, D>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.request<T, R, D>({
      ...config,
      url,
      method: "get",
      data,
    })
  }

  public post<T, R, D>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.request<T, R, D>({
      ...config,
      url,
      method: "post",
      data,
    })
  }

  public patch<T, R, D>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.request<T, R, D>({
      ...config,
      url,
      method: "patch",
      data,
    })
  }

  public delete<T, R, D>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.request<T, R, D>({
      url,
      ...config,
      method: "delete",
      data,
    })
  }
}

export default Http.getInstance()
