export const enum TEAM_ROLE {
  MEMBER,
  LEADER,
}

export const enum TEAM_TYPE {
  CANDIDATE,
  MAVIN,
}

export const enum TEAM_SELECT_STATUS {
  UNKNOWN,
  SHORTLISTED,
}

export const enum GAME_REGION {
  CHINA, //国服
  INTERNATIONAL, // 国际服
}
