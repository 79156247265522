import { useEffect, useState } from "react"
import { t } from "i18next"
import { useParams } from "react-router"

type CreateTeamTimeSelectProps = {
  index: number
  timeType: 0 | 1 | 2
  selected?: string[]
  disabled: boolean
  onChange: (index: number, value: string) => void
}

export const CreateTeamTimeSelect = ({
  index,
  timeType,
  selected,
  onChange,
  disabled,
}: CreateTeamTimeSelectProps) => {
  const [avalibaleTimes, setAvalibaleTimes] = useState<any[]>([])

  const { lng } = useParams()

  useEffect(() => {
    setAvalibaleTimes([
      [
        { label: `${t("Saturday")} 12:00 (UTC+0)`, value: "0" },
        { label: `${t("Saturday")} 14:00 (UTC+0)`, value: "1" },
        { label: `${t("Sunday")} 02:00 (UTC+0)`, value: "2" },
        { label: `${t("Sunday")} 04:00 (UTC+0)`, value: "3" },
        { label: `${t("Sunday")} 06:00 (UTC+0)`, value: "4" },
      ],
      [
        { label: `${t("Saturday")} 20:00 (UTC+8)`, value: "0" },
        { label: `${t("Saturday")} 22:00 (UTC+8)`, value: "1" },
        { label: `${t("Sunday")} 10:00 (UTC+8)`, value: "2" },
        { label: `${t("Sunday")} 12:00 (UTC+8)`, value: "3" },
        { label: `${t("Sunday")} 14:00 (UTC+8)`, value: "4" },
      ],
      [
        { label: `${t("Saturday")} 05:00 (UTC-7)`, value: "0" },
        { label: `${t("Saturday")} 07:00 (UTC-7)`, value: "1" },
        { label: `${t("Saturday")} 19:00 (UTC-7)`, value: "2" },
        { label: `${t("Saturday")} 21:00 (UTC-7)`, value: "3" },
        { label: `${t("Saturday")} 23:00 (UTC-7)`, value: "4" },
      ],
    ])
  }, [lng])

  return (
    <div className="item">
      <div className="lh-select l-relative">
        <select
          onChange={(e) => onChange(index, e.target.value)}
          disabled={disabled}
        >
          <option value="">
            {index === 0 && t("matchTimes1")}
            {index === 1 && t("matchTimes2")}
            {index === 2 && t("matchTimes3")}
          </option>
          {avalibaleTimes.length > 0 &&
            avalibaleTimes[timeType].map(
              (item: { label: string; value: string }) => (
                <option
                  key={item.value}
                  value={item.value}
                  disabled={selected?.includes(item.value)}
                >
                  {item.label}
                </option>
              ),
            )}
        </select>
      </div>
    </div>
  )
}
