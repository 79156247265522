import { useAppDispatch, useAppSelector } from "@/hooks"
import { selectUser } from "@/stores/auth.slice"
import { MouseEvent, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { toggleLoginModal } from "@/stores/modal.slice"
import { t } from "i18next"

export const Sider = () => {
  const scrollTo = (e: MouseEvent, id: string | number) => {
    e.preventDefault()
    // @ts-ignore
    _app._config.setScrollTo(id)
  }

  const { lng } = useParams()

  const currentUser = useAppSelector(selectUser)
  const [hasTeam, setHasTeam] = useState(false)

  useEffect(() => {
    if (!currentUser) setHasTeam(false)
    else if (currentUser.teamId) setHasTeam(true)
    else setHasTeam(false)
  }, [currentUser])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onEnrollClick = (e: MouseEvent) => {
    e.preventDefault()
    if (!currentUser) dispatch(toggleLoginModal())
    else navigate(`/${lng}/enroll`)
  }

  return (
    <aside className="lh-fixed">
      <div className="fixed-nav honghuang l-orange">
        <ul className="l-font18 fixed-item">
          <li>
            <a href="#" onClick={(e) => scrollTo(e, "#Dynamic")}>
              <i></i>
              <span>{t("news")}</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={(e) => scrollTo(e, "#System")}>
              <i></i>
              <span>{t("schedule")}</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={(e) => scrollTo(e, "#Rule")}>
              <i></i>
              <span>{t("rules")}</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={(e) => scrollTo(e, "#Prize")}>
              <i></i>
              <span>{t("rewards")}</span>
            </a>
          </li>
          {/* <li>
            <a href="#" onClick={(e) => scrollTo(e, "#Teams")}>
              <i></i>
              <span>{t("teams")}</span>
            </a>
          </li> */}
        </ul>
        <a
          href="#"
          className="return-top l-flex"
          onClick={(e) => scrollTo(e, 0)}
        >
          <div className="icon">
            <img src="/img/icon11.png" />
          </div>
          <span>TOP</span>
        </a>
      </div>
      {/* <div className="fixed-btn l-mg30">
        {!hasTeam && (
          <>
            <a
              href="#"
              className="index-btn small l-relative l-bold l-white l-font16"
              onClick={(e) => onEnrollClick(e)}
            >
              <img src="/img/btn6.png" />
              <p>{t("createTeam")}</p>
            </a>
            <Link
              to={`/${lng}/teams`}
              className="index-btn small l-relative l-bold l-white l-font16"
            >
              <img src="/img/btn5.png" />
              <p>{t("joinTeam")}</p>
            </Link>
          </>
        )}
        {hasTeam && (
          <Link
            to={`/${lng}/team/${currentUser?.teamMaskId}`}
            className="index-btn small l-relative l-bold l-white l-font16"
          >
            <img src="/img/btn5.png" />
            <p>{t("myTeam")}</p>
          </Link>
        )}
      </div> */}
    </aside>
  )
}
