import { MouseEvent, useEffect, useRef, useState } from "react"
import Modal from "./Modal"
import { Link, useParams } from "react-router-dom"
import Matches from "@/datas/matches.json"
import { t } from "i18next"
import classNames from "classnames"
import { sliceArrayWithLength } from "@/utils"

const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

export const TeamScheduleTab = () => {
  const [openTeamInfoModal, setOpenTeamInfoModal] = useState(false)
  const { lng } = useParams()

  const yearPrefix = "2023/"
  const timeStartSuffix = " 00:00:00"
  const timeEndSuffix = " 23:59:59"
  let { schedules } = Matches

  const getStatus = (schedule: { start: string; end: string }) => {
    const startTime = new Date(
      yearPrefix + schedule.start.replace(/\./g, "/") + timeStartSuffix,
    )
    const endTime = new Date(
      yearPrefix + schedule.end.replace(/\./g, "/") + timeEndSuffix,
    )
    const now = Date.now()

    if (now - startTime.valueOf() <= 0) return "comingSoon"
    else if (endTime.valueOf() - now >= 0) return "progressing"
    else return "ended"
  }

  const dateFomatter = (date: string) => {
    if (lng === "es" || lng === "fr" || lng === "de") {
      const [month, day] = date.split(".")
      return `${day}/${month}`
    } else return date
  }

  const schedulesWithStatus = schedules.map((schedule) => {
    return {
      ...schedule,
      status: getStatus(schedule),
    }
  })

  const [scaleRatio, setScaleRatio] = useState(1)
  const ref = useRef(null)

  const handleSetScaleRatio = () => {
    console.log(scaleRatio)
    if (ref.current) {
      const width = ref.current["clientWidth"]
      if (width <= 732) setScaleRatio(width / 732)
    }
  }

  useEffect(() => {
    handleSetScaleRatio()
    window.addEventListener("resize", handleSetScaleRatio)
    return () => window.removeEventListener("resize", handleSetScaleRatio)
  })

  const [championImg, setChampionImg] = useState("")

  useEffect(() => {
    setChampionImg(`/img/${lng}/champion.jpg`)
  }, [lng])

  const getScheduleDate = (schedule: { start: string; end: string }) => {
    const dateString =
      schedule.start === schedule.end
        ? schedule.start
        : `${schedule.start}~${schedule.end}`
    const formatDateString =
      schedule.start === schedule.end
        ? dateFomatter(schedule.start)
        : `${dateFomatter(schedule.start)}~${dateFomatter(schedule.end)}`
    return isCnSite ? dateString : formatDateString
  }

  return (
    <>
      <section className="lh-news l-mg100">
        <div className="l-container">
          <div className="index-title l-relative">
            <img src="/img/icon1.png" />
            <div className="cont">
              <div>
                <h3 className="name l-font60 honghuang">{t("teams")}</h3>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "var(--size-60)" }}>
            <img src="/img/teams.png" />
          </div>
        </div>
      </section>
      <section className="lh-news l-mg100">
        <div className="l-container">
          <div className="index-title l-relative">
            <img src="/img/icon1.png" />
            <div className="cont" ref={ref}>
              <div>
                {/* <p className="nname l-font26 l-blue impact l-uppercase">
                  part1
                </p> */}
                <h3 className="name l-font60 honghuang">{t("process")}</h3>
              </div>
            </div>
          </div>
          <ul className="game-progress l-grid-2 l-mg60">
            {schedulesWithStatus.length > 0 &&
              schedulesWithStatus.map((schedule, index) => (
                <li key={`rok:schedule-${index}`}>
                  <div>
                    <i className="line"></i>
                    <div className="text">
                      <h3 className="time l-font36 impact l-dominant">
                        {getScheduleDate(schedule)}
                      </h3>
                      <p className="nname l-font26 l-bold">
                        {t(schedule.matchType)}
                      </p>
                    </div>
                    <i className="line"></i>
                    <span
                      className={
                        schedule.status === "progressing"
                          ? "game-progress-type l-font16 type2"
                          : "game-progress-type l-font16"
                      }
                    >
                      {t(schedule.status)}
                    </span>
                  </div>
                </li>
              ))}
          </ul>
          <div className="lh-schedule">
            <img src={championImg} />
          </div>
          <div className="schedule-bottom l-mg15">
            <Link
              to={`/${lng}/rules`}
              className="index-btn l-relative l-bold l-white l-font24"
            >
              <img src="/img/2.0/btn1.png" />
              <p>{t("rules")}</p>
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}
