import Modal from "./Modal"
import { ModalProps } from "./Modal/modalFrame"
import { useCountDown } from "@minax/countdown"
import { getTeam, joinTeam } from "@/services/team.service"
import { useState, useMemo, useRef, MouseEvent } from "react"
import { isValidIDNumber } from "./idNumberChecker"
import { showModal } from "./Modal"
import { sendEmailCaptcha, sendPhoneCaptcha } from "@/services/auth.service"
import classNames from "classnames"
import { Team } from "@/types"
import { t } from "i18next"
import { useAppDispatch } from "@/hooks"
import { updateUserTeamInfo } from "@/stores/auth.slice"
interface Props extends ModalProps {
  team: Team
}

const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

export const JoinFormModal = (props: Props) => {
  const form = useRef(null)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const { team, onClose } = props
  const id = team ? team.id : null
  const isInvitedOnly = team ? team.isInvitedOnly : null

  const rules: { [key: string]: (value?: any) => undefined | string } = useMemo(
    () => ({
      code: (value?: string) => {
        if (isInvitedOnly != null && isInvitedOnly) {
          if (!value?.trim()) return t("emptyInviteCode")
          if (/^([A-Z]|[0-9]){6}$/.test(value.trim().toUpperCase()))
            return t("invalidInviteCode")
        }
      },
      captcha: (value?: string) => {
        if (!value?.trim()) {
          return t("needCaptcha")
        }

        if (!/^[0-9]{6}$/.test(value)) {
          return t("captchaWarn")
        }

        return undefined
      },
      address: (value?: string) => {
        if (!value?.trim()) {
          return t("requireAddress")
        }

        return undefined
      },
      IDNumber: (value?: string) => {
        if (!isCnSite) {
          return undefined
        }

        if (!value?.trim()) {
          return "身份证号必须填写"
        }

        if (!isValidIDNumber(value?.trim())) {
          return "身份证号格式错误"
        }

        return undefined
      },
      email: (value?: string) => {
        // if (isCnSite) {
        //   return undefined
        // }

        if (!value?.trim()) {
          return t("requireEmail")
        }

        const emailReg =
          /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/

        if (!emailReg.test(value?.trim())) {
          return t("inputEmail")
        }

        return undefined
      },
      phone: (value?: string) => {
        if (!isCnSite) {
          return undefined
        }

        if (!value?.trim()) {
          return "手机号必须填写"
        }

        if (!/^1[3456789]\d{9}$/.test(value?.trim())) {
          return "手机号格式错误"
        }

        return undefined
      },
    }),
    [isCnSite, t],
  )

  const verifyData = (payload: { [key: string]: string | string[] }) => {
    const result: { [key: string]: string } = {}
    let pass = true

    Object.entries(payload).forEach(([key, value]) => {
      if (typeof rules[key] === "function") {
        const msg = rules[key](value)

        if (msg) {
          pass = false
          result[key] = msg
        }
      }
    })

    setErrors(result)

    return pass
  }

  const onFormChange = (e: any) => {
    const { name, value } = e.target

    if (typeof rules[name] === "function") {
      const result = rules[name](
        name === "entryTimes" ? value?.split(",") : value?.trim(),
      )
      const newErrors: any = { ...errors }

      if (result) {
        newErrors[name] = result
      } else {
        newErrors[name] = undefined
      }

      setErrors(newErrors)
    }
  }

  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false)

  const [sendCaptchaRest, resetSendCaptcha] = useCountDown(
    "sendCaptchaWithAuthService3",
    {
      total: 60,
      lifecycle: "session",
    },
  )

  const onGetCaptchaClick = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (form.current) {
      const data = new FormData(form.current as HTMLFormElement)
      const key = isCnSite ? "phone" : "email"
      const value = data.get(key) // 这个value就是电话号码或者是邮箱
      const result = isCnSite ? rules.phone(value) : rules.email(value)

      const newErrors: any = { ...errors }
      if (result) {
        newErrors[key] = result
      } else {
        newErrors[key] = undefined

        if (value) {
          resetSendCaptcha()
          isCnSite
            ? sendPhoneCaptcha(value.toString()?.trim())
            : sendEmailCaptcha(value.toString()?.trim())
        }
      }
      setErrors(newErrors)
    }
  }

  const dispatch = useAppDispatch()

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    const data = new FormData(e.target as HTMLFormElement)
    const payload: { [key: string]: string } = {}
    Array.from(data.entries()).forEach(([key, value]) => {
      if (!(value instanceof File)) {
        payload[key] = value?.trim()
      }
    })
    const { code, IDNumber, email, phone, address, captcha } = payload
    const reqData = { address, captcha, email, IDNumber, phone }

    if (verifyData(payload) && id) {
      setSubmitBtnDisabled(true)
      if (isInvitedOnly != null && isInvitedOnly) {
        joinTeam(reqData, undefined, code).then((joinRes) => {
          if (joinRes && joinRes.code === 0) {
            dispatch(
              updateUserTeamInfo({
                teamId: team.id,
                teamMaskId: team.maskId,
              }),
            )
            props.onClose && props.onClose()
            showModal({
              title: t("teamJoined"),
              message: t("joinTeamSuccess"),
            })
          }
          setSubmitBtnDisabled(false)
        })
      } else {
        joinTeam(reqData, id).then((joinRes) => {
          if (joinRes && joinRes.code === 0) {
            dispatch(
              updateUserTeamInfo({
                teamId: team.id,
                teamMaskId: team.maskId,
              }),
            )
            setSubmitBtnDisabled(false)
            props.onClose && props.onClose()

            showModal({
              title: t("teamJoined"),
              message: t("joinTeamSuccess"),
            })
          }
        })
      }
    }
  }

  return (
    <Modal {...props} title={t("fillContactInfo")}>
      <form
        className="join-form"
        ref={form}
        onSubmit={onSubmit}
        onChange={onFormChange}
      >
        <div style={{ marginTop: "var(--size-40)" }}>
          <ul className="join-input l-font16">
            {isInvitedOnly != null && isInvitedOnly == true && (
              <li className={classNames({ error: !!errors.code })}>
                <h3 className="name l-font20">
                  {t("inviteCode")}
                  <span className="l-red">*</span>
                </h3>
                <div className="cont l-relative">
                  <input
                    type="text"
                    name="code"
                    placeholder={t("inputInviteCode")}
                    maxLength={6}
                  />
                  {errors.phone && (
                    <p className="error-tip l-red">{errors.code}</p>
                  )}
                </div>
              </li>
            )}
            {isCnSite && (
              <li className={classNames({ error: !!errors.phone })}>
                <h3 className="name l-font20">
                  手机号<span className="l-red">*</span>
                </h3>
                <div className="cont l-relative">
                  <input
                    type="number"
                    name="phone"
                    placeholder="请输入手机号"
                    maxLength={11}
                  />
                  {errors.phone && (
                    <p className="error-tip l-red">{errors.phone}</p>
                  )}
                </div>
              </li>
            )}
            {!isCnSite && (
              <li className={classNames({ error: !!errors.email })}>
                <h3 className="name l-font20">
                  {t("email")}
                  <span className="l-red">*</span>
                </h3>
                <div className="cont l-relative">
                  <input
                    type="email"
                    name="email"
                    placeholder={t("requireEmail")}
                    maxLength={255}
                  />
                  {errors.email && (
                    <p className="error-tip l-red">{errors.email}</p>
                  )}
                </div>
              </li>
            )}

            <li className={classNames({ error: !!errors.captcha })}>
              <h3 className="name l-font20">
                {t("emailCaptcha")}
                <span className="l-red">*</span>
              </h3>
              <div className="cont l-relative">
                <input
                  type="text"
                  placeholder={t("emailCaptchaPlaceholder")}
                  maxLength={6}
                  name="captcha"
                />
                <button
                  disabled={sendCaptchaRest > 0}
                  className="index-btn l-relative l-bold l-white small"
                  onClick={(e) => onGetCaptchaClick(e)}
                >
                  <img src="/img/btn2.png" />
                  <p>
                    {t("sendCode")}
                    {sendCaptchaRest > 0 && `(${sendCaptchaRest}s)`}
                  </p>
                </button>
                {errors.captcha && (
                  <p className="error-tip l-red">{errors.captcha}</p>
                )}
              </div>
            </li>
            {isCnSite && (
              <>
                <li className={classNames({ error: !!errors.IDNumber })}>
                  <h3 className="name l-font20">
                    身份证号<span className="l-red">*</span>
                  </h3>
                  <div className="cont l-relative">
                    <input
                      type="text"
                      placeholder="请输入身份证号"
                      name="IDNumber"
                      maxLength={18}
                    />
                    {errors.IDNumber && (
                      <p className="error-tip l-red">{errors.IDNumber}</p>
                    )}
                  </div>
                </li>
                <li className={classNames({ error: !!errors.email })}>
                  <h3 className="name l-font20">
                    邮箱<span className="l-red">*</span>
                  </h3>
                  <div className="cont l-relative">
                    <input
                      type="email"
                      name="email"
                      placeholder="请输入邮箱地址"
                      maxLength={255}
                    />
                    {errors.email && (
                      <p className="error-tip l-red">{errors.email}</p>
                    )}
                  </div>
                </li>
              </>
            )}

            <li className={classNames({ error: !!errors.address })}>
              <h3 className="name l-font20">
                {t("address")}
                <span className="l-red">*</span>
              </h3>
              <div className="cont l-relative">
                <input
                  type="text"
                  placeholder={t("requireCaptainAddress")}
                  maxLength={255}
                  name="address"
                />
                {errors.address && (
                  <p className="error-tip l-red">{errors.address}</p>
                )}
              </div>
            </li>
          </ul>
        </div>
        <div className="join-form-item">
          <div className="quit-layer-btn l-flex l-mg60">
            <button
              className="index-btn confirm l-relative l-bold l-orange1 l-font24"
              onClick={() => onClose && onClose()}
            >
              <img src="/img/btn8.png" />
              <p>{t("cancel")}</p>
            </button>
            <button
              disabled={submitBtnDisabled}
              className="index-btn confirm l-relative l-bold l-white l-font24"
            >
              <img src="/img/btn3.png" />
              <p>{t("confirm")}</p>
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
