import { useEffect, useState } from "react"
import Modal from "./Modal"
import { ModalProps } from "./Modal/modalFrame"
import { QuitRecord, getTeamQuitRecords } from "@/services/team.service"
import ReactPaginate from "react-paginate"
import { t } from "i18next"
interface Props extends ModalProps {
  teamId: number
}

const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

export const QuitHistoryModal = (props: Props) => {
  const { teamId } = props
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [records, setRecords] = useState<QuitRecord[]>([])
  const size = 7
  useEffect(() => {
    getTeamQuitRecords(teamId, page, size).then((res) => {
      const { total } = res
      setRecords(res.records)
      if (total && total > size) {
        setPageCount(Math.ceil(total / size))
      }
    })
  }, [page])

  const onPageChange = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected + 1)
  }

  return (
    <Modal {...props} title={t("teamLog")}>
      <div className="quit-code-cont l-mg40">
        <div className="quit-code-scroll" data-lenis-scroll>
          <div className="quit-code-list">
            <div className="quit-code-item l-font20 l-bold">
              <div className="name">{t("nickname")}</div>
              <div className="value">{t("power")}</div>
              <div className="type">{t("status")}</div>
              {/* <div className="time">退出时间</div> */}
            </div>
            {(!records || records.length === 0) && (
              <div className="quit-code-item l-font18 l-gray6">
                <div style={{ textAlign: "center", color: "#ccc" }}>
                  {isCnSite ? "暂无记录" : "No Data"}
                </div>
              </div>
            )}

            {records &&
              records.length > 0 &&
              records.map((record, index) => (
                <div
                  className="quit-code-item l-font18 l-gray6"
                  key={`rok:quit_record_${index}`}
                >
                  <div className="name">
                    <div className="lines lines1">{record.playerName}</div>
                  </div>
                  <div className="value">
                    <div className="lines lines1">{record.power}</div>
                  </div>
                  <div className="type">
                    <div className="lines lines1">
                      {parseInt(record.manner) ? t("quit") : t("kicked")}
                    </div>
                  </div>
                  {/* <div className="time">
                    {shortDateFormatter(new Date(record.quitTime))}
                  </div> */}
                </div>
              ))}
          </div>
        </div>
        <ReactPaginate
          pageCount={pageCount}
          containerClassName="list-page l-flex l-mg40"
          activeClassName="on"
          disabledClassName="paginate_disabled"
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          onPageChange={onPageChange}
          breakLabel="…"
          previousLabel={t("prev")}
          nextLabel={t("next")}
          renderOnZeroPageCount={null}
        />
      </div>
    </Modal>
  )
}
