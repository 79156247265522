import { MouseEvent, useEffect, useState } from "react"
import { t } from "i18next"
import Videos from "@/datas/videos.json"
import classNames from "classnames"
import { useParams } from "react-router"
import ReactPlayer from "react-player"
import MatchLives from "@/datas/matchLives.json"
import { showModal } from "./Modal"
import { addOrIncrStat } from "@/services/stats.service"
import { useGoogleAnalytics } from "react-ga4-gtag"

const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

export const EventTab = () => {
  useEffect(() => {
    // @ts-ignore
    _app.init()
  }, [])

  const isLivingNow = MatchLives.some((match) => match.status === "liveNow")
  const livingStream = MatchLives.find(
    (match) =>
      match.status === "liveNow" && match.liveShow && match.liveShow != null,
  )
  const liveStreamSrc = isCnSite
    ? livingStream?.liveShow?.bilibili.cid
    : `https://youtube.com/embed/${livingStream?.liveShow?.youtube.vid}`

  const { lng } = useParams()

  const getLocalValue = (value: any) => {
    if (typeof value === "string") return value
    else if (typeof value === "object" && value) {
      if (lng && lng in value) return value[lng]
      else if ("en" in value) return value.en
    } else return null
  }

  const getLocalizeVideoInfo = (video: any) => {
    const { src, title, poster } = video
    return {
      src: getLocalValue(src),
      title: getLocalValue(title),
      poster: getLocalValue(poster),
    }
  }

  const [currentVideo, setCurrentVideo] = useState<any>({
    ...Videos[0],
    ...getLocalizeVideoInfo(Videos[0]),
  })

  useEffect(() => {
    if (!isLivingNow) {
      const video = Videos.find((v) => v.id === currentVideo.id)
      setCurrentVideo({
        ...video,
        ...getLocalizeVideoInfo(video),
      })
    }
  }, [lng])

  const onEnded = () => {
    const videos = Videos.filter((v) => v.src != null)
    const currentIndex = videos.findIndex(
      (video) => video.id === currentVideo.id,
    )
    if (currentIndex != videos.length - 1) {
      setCurrentVideo({
        ...videos[currentIndex + 1],
        ...getLocalizeVideoInfo(videos[currentIndex + 1]),
      })
    } else
      setCurrentVideo({
        ...videos[0],
        ...getLocalizeVideoInfo(videos[0]),
      })
  }

  const gtag = useGoogleAnalytics()

  const trackViewVideoEvent = (videoName: string) => {
    if (gtag != null) {
      gtag("event", "ViewVideo", { title: videoName })
    }
  }

  const changeVideoSrc = (e: MouseEvent, video: any) => {
    e.preventDefault()
    if (!isLivingNow && video.src) {
      setCurrentVideo({
        ...video,
        ...getLocalizeVideoInfo(video),
      })
      const name = video.title["zh-CN"] || video.title["en"] || video.title
      addOrIncrStat({
        eid: video.id.toString(),
        name: "视频：" + name,
      })
      trackViewVideoEvent(name)
    } else if (video.src) {
      showModal({
        message: t("canntOp"),
      })
    }
  }

  const trackViewLiveEvent = (matchType: string, match: string) => {
    console.log("gtag: ", gtag)
    if (gtag != null) {
      gtag("event", "ViewLive", {
        match_type: matchType,
        match: match,
        platform: isCnSite ? "Bilibili" : "Youtube",
      })
    }
  }

  const onLiveTrack = () => {
    if (isLivingNow) {
      const eid = isCnSite
        ? livingStream!.liveShow!.bilibili.cid
        : livingStream!.liveShow!.youtube.vid
      const matchType = isCnSite
        ? t(livingStream!.matchType!, { lng: "zh-CN" })
        : t(livingStream!.matchType!, { lng: "en" })
      const match = `${livingStream?.teamA} vs ${livingStream?.teamB}`
      // const match = "分组抽签"

      const platform = isCnSite ? "Bilibili" : "Youtube"
      const name = `直播：[${matchType}]@${platform} - ${match}`
      // const name = `直播：[${match}]@${platform}`
      addOrIncrStat({ eid, name })
      trackViewLiveEvent(match, match)
    }
  }

  const BilibiliLivePlayer = (props: any) => {
    const { cid } = props
    if (cid) {
      return (
        <iframe
          style={{ width: "100%", height: "100%", border: 0 }}
          src={`https://www.bilibili.com/blackboard/live/live-activity-player.html?cid=${cid}&quality=0`}
          allow="autoplay; encrypted-media"
          allowFullScreen
          onLoad={() => onLiveTrack()}
        ></iframe>
      )
    }
  }

  const BilibiliPlayer = (props: any) => {
    const { src } = props
    if (src) {
      return (
        <iframe
          style={{ width: "100%", height: "100%", border: 0 }}
          src={src}
          scrolling="no"
          allow="autoplay; encrypted-media"
          allowFullScreen
          ></iframe>
      )
    }
  }

  return (
    <section className="lh-news l-mg40">
      <div className="l-container">
        {/* <div className="index-title l-relative">
          <img src="/img/icon1.png" />
          <div className="cont">
            <div>
              <h3 className="name l-font70 honghuang">{t("spectate")}</h3>
            </div>
          </div>
        </div> */}
        <div className="news-top l-flex l-mg20">
          <div className="left">
            <div className="news-title l-flex">
              <div className="icon">
                <img src="/img/2.0/icon9.png" />
              </div>
              <b
                className="name lines lines1"
                style={!isLivingNow ? { flex: 1, width: 0 } : {}}
              >
                {isLivingNow ? t("liveNow") : currentVideo.title}
              </b>
              <div className="rhombus">
                <img src="/img/2.0/icon11.png" />
              </div>
              {isLivingNow && livingStream?.teamA !== "--" && (
                <span className="nname">
                  {livingStream!.teamA} vs {livingStream!.teamB}
                </span>
              )}
            </div>
            <div className="news-cont l-mg20">
              <div className="news-lives l-relative">
                <div className="cont">
                  {isCnSite && isLivingNow && (
                    <BilibiliLivePlayer cid={liveStreamSrc} />
                  )}
                  {isCnSite && currentVideo.src.startsWith("//") && (
                    <BilibiliPlayer src={currentVideo.src} />
                  )}
                  {(!isCnSite || !isLivingNow ) && !currentVideo.src.startsWith("//") && (
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      playing
                      onReady={isLivingNow ? () => onLiveTrack() : () => {}}
                      url={isLivingNow ? liveStreamSrc : currentVideo.src}
                      onEnded={onEnded}
                      controls
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="news-title l-flex">
              <div className="icon">
                <img src="/img/2.0/icon10.png" />
              </div>
              <b className="name l-font36">{t("news")}</b>
              <div className="right">
                <div className="rhombus">
                  <img src="/img/2.0/icon11.png" />
                </div>
              </div>
            </div>
            <div className="news-cont l-mg20">
              <div className="news-progress l-relative">
                <div
                  className="news-scroll l-banner"
                  data-webview="auto"
                  id="data-lenis-scroll"
                  data-lenis-scroll
                >
                  <ul className="news-progress-ul swiper-wrapper bd">
                    {MatchLives.map((match, index) => (
                      <li
                        className={classNames("swiper-slide", {
                          on: match.status === "liveNow",
                        })}
                        key={`match-${index}`}
                      >
                        <div>
                          <div className="left l-font16">
                            {match.date && (
                              <>
                                <h3
                                  className={classNames(
                                    "progress-type l-flex lines",
                                    {
                                      type2: match.status === "liveNow",
                                    },
                                  )}
                                  style={{ alignItems: "center" }}
                                >
                                  {isLivingNow &&
                                    match.status === "liveNow" && (
                                      <img src="/img/2.0/icon12.png" />
                                    )}
                                  <span>{t(match.status)}</span>
                                </h3>
                                <h4 className="time l-font36 impact">
                                  {match.time!}
                                </h4>
                                <p className="name">
                                  <span>
                                    <span>{match.date}</span>
                                  </span>
                                  <span>&nbsp;(UTC+0)</span>
                                </p>
                              </>
                            )}
                            {!match.date && (
                              <div className="l-font24 l-gray6">
                                {t("comingSoon")}
                              </div>
                            )}
                          </div>
                          <div className="right">
                            <div className="left">
                              <div className="icon">
                                {match.teamA !== "--" ? (
                                  <img
                                    src={`/img/guidon/flag/${match.teamA}.png`}
                                  />
                                ) : (
                                  <img src="/img/2.0/guidon.png" />
                                )}
                              </div>
                              <h3 className="name l-gray3">{match.teamA!}</h3>
                            </div>
                            <div className="center l-font16">
                              <p className="in">
                                <span>{t(match.matchType!)}</span>
                              </p>
                              <div className="score l-font40 l-bold l-white l-flex">
                                <span>{match.teamAScore || "-"}</span>
                                <i>:</i>
                                <span>{match.teamBScore || "-"}</span>
                              </div>
                            </div>
                            <div className="right">
                              <div className="icon">
                                {match.teamB !== "--" ? (
                                  <img
                                    src={`/img/guidon/flag/${match.teamB}.png`}
                                  />
                                ) : (
                                  <img src="/img/2.0/guidon.png" />
                                )}
                              </div>
                              <h3 className="name l-gray3">{match.teamB!}</h3>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {/* <ul className="news-scroll-bd bannerHd l-flex"></ul> */}
                </div>
                <div className="news-progress-bttom">
                  <a
                    href={
                      isCnSite
                        ? "https://space.bilibili.com/438322680/video"
                        : "https://www.youtube.com/@RiseofKingdoms/streams"
                    }
                    className="index-btn l-relative l-white l-font18"
                    target="_blank"
                  >
                    <img src="/img/2.0/btn.png" />
                    <p>{t("watchFullMatch")}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="news-bottom-n bannerBox l-relative l-mg20">
          <div
            className="news-bottom-banner l-banner l-overflow"
            data-view="4"
            data-webview="auto"
            data-paginationType="progressbar"
          >
            <ul className="news-bottom swiper-wrapper bd">
              {Videos.map((video, index) => (
                <li className="swiper-slide" key={`video-${index}`}>
                  <a href="#" onClick={(e) => changeVideoSrc(e, video)}>
                    <div className="img">
                      <img src={getLocalValue(video.poster)} />
                    </div>
                    <h3 className="name l-flex l-white l-font18 lines lines2">
                      {!isLivingNow && currentVideo.id === video.id && (
                        <img src="/img/2.0/icon12.png" />
                      )}
                      <span>{getLocalValue(video.title)}</span>
                    </h3>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="news-bottom-jt bannerBtn">
            <a href="#" className="_nPrev prevs">
              <img src="/img/jtleft.png" />
            </a>
            <a href="#" className="_nNext nexts">
              <img src="/img/jtright.png" />
            </a>
          </div>
        </div>

        {/* <div
          className="news-bottom-banner l-banner l-overflow l-mg20"
          data-view="4"
          data-webview="auto"
          data-paginationType="progressbar"
        >
          <ul className="news-bottom swiper-wrapper bd">
            {outerVideosWithRegion.map((video) => (
              <li key={`outer-video-${video.id}`} className="swiper-slide">
                <a href={video.href} target="_blank">
                  <div className="img">
                    <img src={video.poster} />
                  </div>
                  <h3 className="name l-flex l-white l-font16 lines lines1">
                    {!isLivingNow && currentVideo.id === video.id && (
                      <img src="/img/2.0/icon12.png" />
                    )}
                    <span>{video.title}</span>
                  </h3>
                </a>
              </li>
            ))}
          </ul>
          <ul className="news-bottom-hd bannerHd l-mg30"></ul>
        </div> */}
      </div>
    </section>
  )
}
