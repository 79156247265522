import { useEffect, useState, MouseEvent } from "react"
import { t } from "i18next"
import { useParams } from "react-router"

type Props = {
  afterAgree?: () => void
}

export function Agreement(props: Props) {
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [selectStyle, setSelectStyle] = useState("icon")

  useEffect(() => {
    const radioStyle = btnDisabled ? "icon" : "icon on"
    setSelectStyle(radioStyle)
  }, [btnDisabled])

  const toggleAgree = (e: MouseEvent) => {
    e.preventDefault()
    setBtnDisabled(!btnDisabled)
  }

  const { afterAgree } = props
  const onBtnClick = () => {
    afterAgree && afterAgree()
  }

  const [content, setContent] = useState(t("rulesContent"))

  const { lng } = useParams()

  useEffect(() => {
    setContent(t("rulesContent"))
  }, [lng])

  return (
    <main>
      <section className="lh-banner ban l-relative">
        <img src="/img/ban.png" />
      </section>
      <section className="lh-other">
        <div className="l-container">
          <div className="other-cont">
            <div className="border"></div>
            <div className="index-title l-relative">
              <img src="/img/bicon3.png" />
              <div className="cont">
                <div>
                  <h3 className="name l-font70 honghuang">{t("rules")}</h3>
                </div>
              </div>
            </div>
            <div className="manage-tip l-center l-font28 l-pd50 l-bold">
              {t("regDeadline")}&nbsp;&nbsp;&nbsp;
              <span className="l-orange impactMTStd">
                2023/10/13 23:59 UTC+0
              </span>
            </div>
            <div className="rule-confirm-cont">
              <div className="rule-scroll l-font16 " id="data-lenis-scroll">
                <h3 className="title l-font30 l-bold l-center l-orange">
                  {t("rulesTitle")}
                </h3>
                <div className="cont l-mg40">
                  <pre dangerouslySetInnerHTML={{ __html: content }}></pre>
                </div>
              </div>
            </div>
            <div className="rule-agree l-flex l-font16 l-gray6 l-mg50">
              {/* <!--同意添加on--> */}
              <div
                className={selectStyle}
                style={{ cursor: "pointer" }}
                onClick={toggleAgree}
              >
                <img src="/img/ok.png" />
              </div>
              <span style={{ cursor: "pointer" }} onClick={toggleAgree}>
                {t("iAgree")}
              </span>
            </div>
            <div className="form-btn l-flex l-mg40">
              <button
                className="index-btn max l-relative l-bold l-white l-font32"
                disabled={btnDisabled}
                onClick={onBtnClick}
              >
                <img src="/img/btn10.png" />
                <p>{t("createTeam")}</p>
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
