import Modal from "./Modal"
import { ModalProps } from "./Modal/modalFrame"
import { copyTextToClipboard } from "@/utils"
import { useCountDown } from "@minax/countdown"
import { changTeamInviteCode } from "@/services/team.service"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { Team } from "@/types"
import { t } from "i18next"
interface Props extends ModalProps {
  code: string
  team: Team
}

const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

export const InvitionModal = (props: Props) => {
  const { team } = props
  const { id, maskId, isInvitedOnly } = team
  const host = window.location.host
  const protocol = window.location.href.startsWith("http://")
    ? "http://"
    : "https://"

  const { lng } = useParams()
  const [code, setCode] = useState(team.inviteCode)
  const teamLink = `${protocol}${host}/${lng}/team/${maskId}`

  const [copyContent, setCopyContent] = useState("")

  useEffect(() => {
    if (isInvitedOnly != null && isInvitedOnly) {
      setCopyContent(
        `${t("teamLink")}: ${teamLink}  ${t("inviteCode")}: ${code}`,
      )
    } else setCopyContent(teamLink)
  }, [code, lng])

  const [copyRestTime, copyResetCountdown] = useCountDown("copyInviteLink", {
    total: 2,
    lifecycle: "session",
  })

  const [changeRestTime, changeResetCountdown] = useCountDown(
    "changeInviteCode",
    {
      total: 30,
      lifecycle: "session",
    },
  )

  const copyLink = () => {
    copyTextToClipboard(copyContent, copyResetCountdown)
  }

  const changeInviteCode = () => {
    changeResetCountdown()
    changTeamInviteCode(id).then((res) => {
      if (res) {
        setCode(res)
      }
    })
  }

  return (
    <Modal {...props} title={t("inviteFriends")}>
      <div className="quit-layer-cont l-pd50">
        <p style={{ textAlign: "center", marginBottom: "20px", color: "#666" }}>
          {t("copyTips")}
        </p>
        <pre
          className="text l-font24 l-center impact"
          style={{ color: "#444" }}
        >
          {teamLink}
        </pre>
        {isInvitedOnly != null && isInvitedOnly == true && (
          <>
            <p style={{ textAlign: "center", margin: "12px 0", color: "#666" }}>
              {t("invitedOnly")}
            </p>
            <pre className="text l-font40 l-center impact">{code}</pre>
          </>
        )}

        <div className="quit-layer-btn l-flex l-mg60">
          {isInvitedOnly != null && isInvitedOnly == true && (
            <button
              onClick={changeInviteCode}
              className="index-btn confirm l-relative l-bold l-orange1 l-font24"
              disabled={changeRestTime > 0}
            >
              <img src="/img/btn8.png" />

              <p>
                {changeRestTime > 0
                  ? `${t("changed")}(${changeRestTime}s)`
                  : t("changeCode")}
              </p>
            </button>
          )}
          <button
            onClick={copyLink}
            className="index-btn confirm l-relative l-bold l-white l-font24"
            disabled={copyRestTime > 0}
          >
            <img src="/img/btn3.png" />
            <p>{copyRestTime > 0 ? t("copied") : t("copyLink")}</p>
          </button>
        </div>
      </div>
    </Modal>
  )
}
