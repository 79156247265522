import Header from "@/components/Header"
import Footer from "@/components/Footer"
import LoginFormModal from "@/components/LoginFormModal"
import { useAppDispatch, useAppSelector } from "@/hooks"
import { selectIsLoginModalOpen, toggleLoginModal } from "@/stores/modal.slice"
import { useParams } from "react-router"
import { FooterEn } from "@/components/Footer-en"
import { useEffect, useState } from "react"
import { t } from "i18next"
import { selectUser, updateUserTeamInfo } from "@/stores/auth.slice"
import { getMyTeamBriefInfo } from "@/services/team.service"

interface Props {
  children: React.ReactNode
}

function Layout(props: Props) {
  useEffect(() => {
    // @ts-ignore
    if (_app._config.viewport.width > 1024) {
      // @ts-ignore
      _app._config.fixedScrollHeader() //header下滚上滚效果
    }
    // @ts-ignore
    _app.init()
    // @ts-ignore
    // _app._config.lenisInit()
  }, [])

  const openLoginModal = useAppSelector(selectIsLoginModalOpen)

  const dispatch = useAppDispatch()
  const { lng } = useParams()
  const currentUser = useAppSelector(selectUser)

  useEffect(() => {
    let isDone = true
    if (currentUser) {
      const updateTeamInfo = async () => {
        const team = await getMyTeamBriefInfo()
        if (isDone) {
          if (team?.id !== currentUser.teamId) {
            dispatch(
              updateUserTeamInfo({
                teamId: team?.id,
                teamMaskId: team?.maskId,
              }),
            )
          }
        }
      }
      updateTeamInfo().catch(console.error)
    }

    return () => {
      isDone = false
    }
  }, [])

  const [lngStyle, setLngStyle] = useState("")

  useEffect(() => {
    setLngStyle(`lng-${lng}`)
    document.title = t("title")
    document
      .querySelector("meta[name=description]")
      ?.setAttribute("content", t("description"))
    document
      .querySelector("meta[name=keywords]")
      ?.setAttribute("content", t("keywords"))
  }, [lng])

  return (
    <>
      <div className={lngStyle}>
        <Header />
        {props.children}
        {lng === "zh-CN" && <Footer />}
        {lng !== "zh-CN" && <FooterEn />}
        <LoginFormModal
          open={openLoginModal}
          onClose={() => dispatch(toggleLoginModal())}
        />
      </div>
    </>
  )
}

export default Layout
