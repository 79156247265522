import Modal from "./Modal"
import { GAME_REGION } from "@/enums"
import { MouseEvent, useState, useEffect } from "react"
import { ModalProps } from "./Modal/modalFrame"
import { sendGameCaptcha, login } from "@/services/auth.service"
import Form, { Field } from "rc-field-form"
import { useAppDispatch } from "@/hooks"
import Input from "./Input"
import { loginAction } from "@/stores/auth.slice"
import { toggleLoginModal } from "@/stores/modal.slice"
import { useCountDown } from "@minax/countdown"
import { useParams } from "react-router"
import { t } from "i18next"

function LoginFormModal(props: ModalProps) {
  const dispatch = useAppDispatch()
  const [showTip, setShowTip] = useState(false)

  const { lng } = useParams()

  const [iOSDownloadUrl, setIOSDownloadUrl] = useState("")
  const [androidDownloadUrl, setAndroidDownloadUrl] = useState("")

  useEffect(() => {
    switch (lng) {
      case "zh-CN":
        setIOSDownloadUrl(
          "https://rok-cn.lilith.com/download/index.html?browser=ios",
        )
        setAndroidDownloadUrl(
          "https://led.lilithgame.com/download/game?app_id=1173112&channel_type=rokofficial",
        )
        break
      case "zh-TW":
        setIOSDownloadUrl("https://itunes.apple.com/app/id1436964519")
        setAndroidDownloadUrl(
          "https://play.google.com/store/apps/details?id=com.lilithgame.roc.gp.tw",
        )
        break
      case "ko":
        setIOSDownloadUrl(
          "https://apps.apple.com/kr/app/%EB%9D%BC%EC%9D%B4%EC%A6%88-%EC%98%A4%EB%B8%8C-%ED%82%B9%EB%8D%A4%EC%A6%88/id1474599374",
        )
        setAndroidDownloadUrl(
          "https://play.google.com/store/apps/details?id=com.lilithgames.rok.gpkr",
        )
        break
      case "vi":
        setIOSDownloadUrl("https://itunes.apple.com/app/id1354260888")
        setAndroidDownloadUrl(
          "https://play.google.com/store/apps/details?id=com.lilithgame.roc.gp",
        )
        break
      case "ja":
        setIOSDownloadUrl("https://app.adjust.com/q8zj6b2")
        setAndroidDownloadUrl("https://app.adjust.com/68eh9hw")
        break
      default:
        setIOSDownloadUrl("https://itunes.apple.com/app/id1354260888")
        setAndroidDownloadUrl(
          "https://play.google.com/store/apps/details?id=com.lilithgame.roc.gp",
        )
    }
  }, [lng])

  const toggleUidTips = (e: MouseEvent) => {
    e.preventDefault()
    // @ts-ignore
    const triggerEl = $(".uid-tips-trigger")
    const text = triggerEl.text()
    text === t("whatsPlayerID")
      ? triggerEl.text(t("iSee"))
      : triggerEl.text(t("whatsPlayerID"))
    // @ts-ignore
    _app._config.toggleClass({
      // @ts-ignore
      boxCell: triggerEl.parents("li"),
      className: "whatTip",
    })
  }

  const [formErr, setFormErr] = useState("")
  const [loginForm] = Form.useForm()

  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false)

  const isCnSite = import.meta.env.VITE_INSTANCE === "cn"
  const region = isCnSite ? GAME_REGION.CHINA : GAME_REGION.INTERNATIONAL

  const handleSubmit = () => {
    const { playerId, captcha } = loginForm.getFieldsValue()
    let emptyErr = ""
    let codeErr = ""
    if (!playerId || !captcha || !/\d{6}/.test(captcha)) {
      const emptyFields = []
      if (!playerId) emptyFields.push(t("playerIDPlaceholder"))
      if (!captcha) emptyFields.push(t("needCaptcha"))

      if (emptyFields.length > 0) emptyErr = emptyFields.join(", ")
      if (captcha && !/\d{6}/.test(captcha)) codeErr = t("captchaWarn")

      const errors = []
      if (emptyErr.length > 0) errors.push(emptyErr)
      if (codeErr.length > 0) errors.push(codeErr)

      setFormErr(errors.join("; "))
      return
    }

    setSubmitBtnDisabled(true)
    login({
      region,
      playerId: playerId.trim(),
      captcha: captcha.trim(),
    }).then((res) => {
      if (res) {
        dispatch(loginAction(res as { user: any; token: string }))
        dispatch(toggleLoginModal())
      }
      setSubmitBtnDisabled(false)
    })
  }

  const [restTime, resetCountdown] = useCountDown(
    "sendGameCaptchaInLoginModal",
    {
      total: 60,
      lifecycle: "session",
    },
  )

  const handleSendCaptcha = () => {
    const values = loginForm.getFieldsValue()
    const { playerId } = loginForm.getFieldsValue()
    if (!playerId.trim()) {
      setFormErr(t("needPlayerID"))
      return
    }
    // const region = GAME_REGION.INTERNATIONAL
    resetCountdown()
    sendGameCaptcha({ region, playerId }).then((res) => {
      if (res && res.code === 0) {
      }
    })
  }

  const onClose = () => {
    setFormErr("")
    dispatch(toggleLoginModal())
  }

  return (
    <Modal
      {...props}
      formRef={loginForm}
      title={t("playerIDLogin")}
      onClose={onClose}
    >
      <div className="login-form l-mg30">
        {formErr && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#FFDDD2",
              height: "28px",
              lineHeight: "28px",
              textAlign: "center",
              borderRadius: "28px",
              color: "#db5657",
              fontSize: "14px",
              marginBottom: "16px",
            }}
          >
            {formErr}
          </div>
        )}

        <Form form={loginForm} onChange={() => setFormErr("")}>
          <ul className="login-form-item l-font16">
            <li>
              <Field name="playerId">
                <Input
                  type="text"
                  placeholder={t("requirePlayerID")}
                  maxLength="10"
                />
              </Field>
              <div className="tip">
                <a
                  href="#"
                  className="l-orange"
                  onClick={() => setShowTip(!showTip)}
                >
                  {showTip ? t("iSee") : t("whatsPlayerID")}
                </a>
              </div>
              <div
                className="login-tip l-mg30"
                style={{ display: showTip ? "block" : "none" }}
              >
                <p className="l-font18">{t("playerIDTips")}</p>
                <div className="img l-mg10">
                  <img src="/img/tip.jpg" />
                </div>
              </div>
            </li>
            <li>
              <Field name="captcha">
                <Input
                  type="text"
                  placeholder={t("gameMailCaptchaTips")}
                  maxLength="6"
                />
              </Field>
              <button
                className="index-btn l-relative l-bold l-white small"
                disabled={restTime > 0}
                onClick={handleSendCaptcha}
              >
                <img src="/img/btn2.png" />
                <p>
                  {t("sendCode")}
                  {restTime > 0 && `(${restTime}s)`}
                </p>
              </button>
            </li>
          </ul>
          <div className="form-btn l-flex">
            <button
              className="index-btn confirm l-relative l-bold l-white l-font24"
              disabled={submitBtnDisabled}
              onClick={handleSubmit}
            >
              <img src="/img/btn3.png" />
              <p>{t("confirm")}</p>
            </button>
          </div>
        </Form>
        <div className="login-download l-font18 l-mg30">
          <p className="l-center">{t("downloadTips")}</p>
          <div className="download-btn l-flex l-mg20">
            <a href={iOSDownloadUrl}>
              <img src="/img/appstore.png" />
            </a>
            {isCnSite && (
              <a href={androidDownloadUrl}>
                <img src="/img/andriod_download.png" />
              </a>
            )}
            {!isCnSite && (
              <a href={androidDownloadUrl}>
                <img src="/img/googleplay.png" />
              </a>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LoginFormModal
