import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "@/stores"
import { login, LoginDto } from "@/services/auth.service"

interface LoginRes {
  user: {
    id: number
    playerName: string
    teamId: number | null | undefined
    teamMaskId: string | null | undefined
  }
  token: string
}

interface teamInfoPayload {
  teamId: number | null | undefined
  teamMaskId: string | null | undefined
}

export interface AuthSlice {
  user: {
    id: number
    playerName: string
    teamId: number | null | undefined
    teamMaskId: string | null | undefined
  } | null
}

const initialState: AuthSlice = {
  // @ts-ignore
  user: JSON.parse(localStorage.getItem("user")),
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginAction: (state, action: PayloadAction<LoginRes>) => {
      if (action.payload) {
        const { user, token } = action.payload
        if (user && token) {
          if (user.playerName === "") user.playerName = "(昵称不可用)"
          state.user = user
          localStorage.setItem("user", JSON.stringify(user))
          localStorage.setItem("token", token)
        }
      }
    },
    logoutAction: (state) => {
      state.user = null
      localStorage.removeItem("user")
      localStorage.removeItem("token")
    },
    updateUserTeamInfo: (state, action: PayloadAction<teamInfoPayload>) => {
      if (action.payload) {
        const { teamId, teamMaskId } = action.payload
        if (state.user) {
          const userWithNewTeamInfo = {
            ...state.user,
            teamId,
            teamMaskId,
          }
          state.user = userWithNewTeamInfo
          localStorage.setItem("user", JSON.stringify(userWithNewTeamInfo))
        }
      }
    },
  },
})

export const { loginAction, logoutAction, updateUserTeamInfo } =
  authSlice.actions

export const selectUser = (state: RootState) => state.auth.user
// 用法： const user = useAppSelector(selectUser);

export default authSlice.reducer
