import React, {
  ReactNode,
  MouseEvent,
  useState,
  useEffect,
  forwardRef,
  Ref,
} from "react"
import { FormInstance } from "rc-field-form"

export interface ModalProps {
  open?: boolean
  title?: ReactNode
  onClose?: () => void
  children?: ReactNode
  message?: ReactNode
  formRef?: FormInstance
  disabledClose?: boolean
}

const openStyle = {
  opacity: 1,
  pointerEvents: "auto" as React.CSSProperties["pointerEvents"],
}

const closeStyle = {
  opacity: 0,
  pointerEvents: "none" as React.CSSProperties["pointerEvents"],
}

const ModalFrame = (props: ModalProps, ref: Ref<HTMLElement>) => {
  const { title, open, message, onClose, children, formRef, disabledClose } =
    props

  const [close, setClose] = useState(false)

  useEffect(() => {
    setClose(!open)
  }, [open])

  useEffect(() => {
    if (formRef) {
      formRef.resetFields()
    }
  }, [formRef])

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setClose(true)
    onClose && onClose()
  }

  return (
    <aside
      className="login-layer l-layer"
      style={close ? closeStyle : openStyle}
      ref={ref}
    >
      <div className="cont l-relative">
        <div className="bg">
          <img src="/img/icon13.png" />
          <img src="/img/icon14.png" />
        </div>
        {!disabledClose && (
          <a href="#" className="close" onClick={handleClose}>
            <img src="/img/close.png" />
          </a>
        )}

        {title && (
          <h3 className="title l-font36 l-bold l-center l-orange">{title}</h3>
        )}
        <div className="info">
          {children}
          {!children && message}
        </div>
      </div>
    </aside>
  )
}

export default forwardRef(ModalFrame)
