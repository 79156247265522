import { GAME_REGION } from "@/enums"
import http from "@/http"
import { User } from "@/types"

export interface LoginDto {
  region: GAME_REGION
  playerId: string
  captcha: string
}

export function sendGameCaptcha(gameUserDto: {
  playerId: string
  region: GAME_REGION
}): Promise<{ code: number; msg: string }> {
  return http.post("/auth/captcha/game", gameUserDto)
}

export function sendPhoneCaptcha(
  phone: string,
): Promise<{ code: number; msg: string }> {
  return http.post("/auth/captcha/phone", { phone })
}

export function sendEmailCaptcha(
  email: string,
): Promise<{ code: number; msg: string }> {
  return http.post("/auth/captcha/email", { email })
}

export const login = async (
  loginDto: LoginDto,
): Promise<{ user: User; token: string }> => {
  return http.post("/auth/login", loginDto)
}
