import http from "@/http"

interface StatDto {
  eid: string
  name: string
}

export const addOrIncrStat = (stat: StatDto) => {
  return http.post("/stats", stat)
}
