import { MouseEvent, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Sider } from "@/components/Sider"
import { OverviewTab } from "@/components/OverviewTab"
import { EventTab } from "@/components/EventTab"
import { TeamScheduleTab } from "@/components/TeamScheduleTab"
import { t } from "i18next"
import { addOrIncrStat } from "@/services/stats.service"
import { useGoogleAnalytics } from "react-ga4-gtag"
import BannerKV from "@/datas/bannerKV.json"

export function Home() {
  const { lng } = useParams()

  const [iOSDownloadUrl, setIOSDownloadUrl] = useState(
    "https://itunes.apple.com/app/id1354260888",
  )
  const [androidDownloadUrl, setAndroidDownloadUrl] = useState(
    "https://play.google.com/store/apps/details?id=com.lilithgame.roc.gp",
  )
  const [windowsDownloadUrl, setWindowsDownloadUrl] = useState(
    "https://rokwgb.onelink.me/11IZ/e62kw5hp",
  )
  const [macDownloadUrl, setMacDownloadUrl] = useState(
    "https://itunes.apple.com/app/id1354260888",
  )

  const [banner, setBanner] = useState(BannerKV.en)

  const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

  useEffect(() => {
    switch (lng) {
      case "zh-CN":
        setIOSDownloadUrl(
          "https://rok-cn.lilith.com/download/index.html?browser=ios",
        )
        setAndroidDownloadUrl(
          "https://led.lilithgame.com/download/game?app_id=1173112&channel_type=rokofficial",
        )
        setWindowsDownloadUrl(
          "https://led.lilithgame.com/download/game?app_id=1173112&channel_type=rokpc_cn",
        )
        break
      case "zh-TW":
        setIOSDownloadUrl("https://itunes.apple.com/app/id1436964519")
        setAndroidDownloadUrl(
          "https://play.google.com/store/apps/details?id=com.lilithgame.roc.gp.tw",
        )
        setWindowsDownloadUrl("https://rokwgb.onelink.me/11IZ/98y3z393")
        setMacDownloadUrl("https://itunes.apple.com/app/id1436964519")
        break
      case "ko":
        setIOSDownloadUrl(
          "https://apps.apple.com/kr/app/%EB%9D%BC%EC%9D%B4%EC%A6%88-%EC%98%A4%EB%B8%8C-%ED%82%B9%EB%8D%A4%EC%A6%88/id1474599374",
        )
        setAndroidDownloadUrl(
          "https://play.google.com/store/apps/details?id=com.lilithgames.rok.gpkr",
        )
        setWindowsDownloadUrl("https://rokwgb.onelink.me/11IZ/zjawehy5")
        setMacDownloadUrl("https://itunes.apple.com/app/id1474599374")
        break
      case "ja":
        setIOSDownloadUrl("https://app.adjust.com/q8zj6b2")
        setAndroidDownloadUrl("https://app.adjust.com/68eh9hw")
        setWindowsDownloadUrl("https://rokwgb.onelink.me/11IZ/uf4iud14")
        setMacDownloadUrl("https://itunes.apple.com/app/id1483025097")
        break
      default:
        setIOSDownloadUrl("https://itunes.apple.com/app/id1354260888")
        setAndroidDownloadUrl(
          "https://play.google.com/store/apps/details?id=com.lilithgame.roc.gp",
        )
        setWindowsDownloadUrl("https://rokwgb.onelink.me/11IZ/e62kw5hp")
        setMacDownloadUrl("https://itunes.apple.com/app/id1354260888")
    }

    if (lng && lng in BannerKV) {
      const Banners = BannerKV as any
      setBanner(Banners[lng])
    }
  }, [lng])

  const [currentTab, setCurrentTab] = useState("events")

  const changeTab = (e: MouseEvent, tab: string) => {
    e.preventDefault()
    setCurrentTab(tab)
  }

  const gtag = useGoogleAnalytics()

  const trackDownloadEvent = (type: string) =>
    gtag("event", "AppDownload", { type })

  const handleDownload = (type: "iOS" | "Android" | "Windows" | "Mac") => {
    addOrIncrStat({
      eid: type + "-Download",
      name: "下载：" + type,
    }).then(() => {
      switch (type) {
        case "iOS":
          trackDownloadEvent("iOS")
          break
        case "Android":
          trackDownloadEvent("Android")
          break
        case "Windows":
          trackDownloadEvent("Windows")
          break
        case "Mac":
          trackDownloadEvent("Mac")
          break
      }
    })
  }

  return (
    <>
      <main>
        <section className="lh-banner l-relative">
          {/* <img src={`/img/${lng}/banner.png`} alt="" /> */}
          <video
            src={banner.src}
            // poster={banner.poster}
            muted
            playsInline
            autoPlay
            loop
            style={{ zIndex: 1 }}
          ></video>

          <div className="cont" style={{ zIndex: 2 }}>
            <div>
              {/* <div className="name">
                <img src="/img/a1.png" alt="" />
              </div> */}
              <div className="download-btn l-flex l-mg10">
                <a
                  href={iOSDownloadUrl}
                  onClick={() => handleDownload("iOS")}
                  target="_blank"
                >
                  <img src="/img/appstore.png" alt="" />
                </a>
                {isCnSite && (
                  <>
                    <a
                      href={androidDownloadUrl}
                      onClick={() => handleDownload("Android")}
                      target="_blank"
                    >
                      <img src="/img/andriod_download.png" alt="" />
                    </a>
                    <a
                      href={windowsDownloadUrl}
                      onClick={() => handleDownload("Windows")}
                      target="_blank"
                      className="mobile_display_none"
                    >
                      <img src="/img/desktop.png" alt="" />
                    </a>
                  </>
                )}
                {!isCnSite && (
                  <>
                    <a
                      href={androidDownloadUrl}
                      onClick={() => handleDownload("Android")}
                      target="_blank"
                    >
                      <img src="/img/googleplay.png" alt="" />
                    </a>
                    <a
                      href={windowsDownloadUrl}
                      onClick={() => handleDownload("Windows")}
                      target="_blank"
                      className="mobile_display_none"
                    >
                      <img src="/img/Windows.png" alt="" />
                    </a>
                    <a
                      href={macDownloadUrl}
                      onClick={() => handleDownload("Mac")}
                      target="_blank"
                      className="mobile_display_none"
                    >
                      <img src="/img/MAS.png" alt="" />
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="index-nav honghuang l-relative">
          <div className="l-container">
            <div className="index-nav-item l-font40 l-flex">
              <a
                href="#"
                className={currentTab === "overview" ? "on" : ""}
                onClick={(e) => changeTab(e, "overview")}
              >
                <img src="/img/2.0/jtr.png" />
                <b>{t("overview")}</b>
                <img src="/img/2.0/jtl.png" />
              </a>
              <a
                href="#"
                className={currentTab === "events" ? "on" : ""}
                onClick={(e) => changeTab(e, "events")}
              >
                <img src="/img/2.0/jtr.png" />
                <b>{t("news")}</b>
                <img src="/img/2.0/jtl.png" />
              </a>
              <a
                href="#"
                className={currentTab === "teamSchedule" ? "on" : ""}
                onClick={(e) => changeTab(e, "teamSchedule")}
              >
                <img src="/img/2.0/jtr.png" />
                <b>{t("schedules")}</b>
                <img src="/img/2.0/jtl.png" />
              </a>
            </div>
          </div>
        </section>
        {currentTab === "overview" && <OverviewTab />}
        {currentTab === "events" && <EventTab />}
        {currentTab === "teamSchedule" && <TeamScheduleTab />}
        {/* <div className="index-bg">
          <img src="/img/icon4.png" />
        </div> */}
      </main>
      {currentTab === "overview" && <Sider />}
    </>
  )
}
