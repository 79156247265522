const isCnSite = import.meta.env.VITE_INSTANCE === "cn"

const prodApiUrl = isCnSite
  ? "https://esports-rok-cn-be.lilith.com/api"
  : "https://esports-rok-be.lilith.com/api"

export const baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.BASE_API_URL || prodApiUrl
    : "http://localhost:3003/api"

export const signUpEndDate = process.env.SIGNUP_END_DATE
  ? new Date(process.env.SIGNUP_END_DATE)
  : new Date('2023-10-13T23:59:59.000+08:00')
